define("comunidades-nafin/components/course-evaluation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    bus: Ember.inject.service('evaluacion-curso'),
    didInsertElement: function didInsertElement() {
      var self = this;
      setInterval(function () {
        self.LoadData();
      }, 10000);
    },
    setEvaluacion: function setEvaluacion(r, self) {
      self.set('evaluacion', r);
    },
    LoadData: function LoadData() {
      var url_page = window.location.href;
      var url_page = window.location.href.split("/");
      var id = url_page[url_page.length - 1]; //url_page.substring(url_page.lastIndexOf('/') + 1);

      var self = this;
      window.isHabilitarCalifRa = false;
      Ember.$.ajax({
        url: constant.APIURL + 'lms/alumno/listarrutas/listarmisras',
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        contentType: 'application/x-www-form-urlencoded',
        data: 'params={"ra_k":' + id + '}',
        success: function success(r) {
          var response = JSON.parse(r);
          var ra;
          Object.keys(response.data).forEach(function (idx) {
            ra = response.data[idx].intento_ra;
          });
          Ember.$.ajax({
            url: constant.APIURL + 'usuario/datospersonales/',
            type: 'POST',
            xhrFields: {
              withCredentials: true
            },
            contentType: 'application/x-www-form-urlencoded',
            success: function success(r) {
              var usuario = JSON.parse(r);
              Ember.$.ajax({
                url: constant.APIURL + 'lms/raprendizaje/listarProgreso',
                type: 'POST',
                xhrFields: {
                  withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                data: 'usuario_k=' + usuario.usuario_k + '&llave_propietario_k=' + id + '&intento_ra=' + ra + '&cat=is null',
                success: function success(r) {
                  var respons = JSON.parse(r);
                  Object.keys(respons.data).forEach(function (idx) {
                    if (respons.data[idx].numero_intentos === null) {
                      respons.data[idx].numero_intentos = "0";
                    }

                    if (respons.data[idx].habilitar_calificar_ra == 1) {
                      window.datosScoredUser = {
                        "id_user": respons.ra.usuario_k,
                        "id_course": respons.ra.ra_k,
                        "evaluation": idx
                      };
                    } else {
                      window.datosScoredUser = {
                        "id_user": -2,
                        "id_course": -2,
                        "evaluation": idx
                      };
                    } //Label de los botones


                    if (respons.data[idx].categoria_objeto_aprendizaje === 'Autoevaluación' || respons.data[idx].categoria_objeto_aprendizaje === 'Evaluaciones' || respons.data[idx].categoria_objeto_aprendizaje === 'Finales') {
                      respons.data[idx].categoria_objeto_aprendizaje += ' / Evaluación';
                      respons.data[idx].boton_label = "APLICAR";
                    } else if (respons.data[idx].categoria_objeto_aprendizaje === 'Nafin') {
                      respons.data[idx].categoria_objeto_aprendizaje += ' / Curso Online';
                      respons.data[idx].boton_label = "INGRESAR";
                    } else if (respons.data[idx].categoria_objeto_aprendizaje === 'Nafin' && respons.data[idx].estatus === "8") {
                      respons.data[idx].categoria_objeto_aprendizaje += ' / Curso Online';
                      respons.data[idx].boton_label = "VISUALIZAR";
                    } else if (respons.data[idx].categoria_objeto_aprendizaje === 'Encuestas') {
                      respons.data[idx].categoria_objeto_aprendizaje += ' / Encuesta';
                      respons.data[idx].boton_label = "APLICAR";
                    } else {
                      respons.data[idx].categoria_objeto_aprendizaje += ' / Actividades';
                      respons.data[idx].boton_label = "APLICAR";
                    } //fix 	


                    if (respons.data[idx].numero_intentos.indexOf('/') != -1) {
                      respons.data[idx].numero_intentos = respons.data[idx].numero_intentos.split('/')[0];
                    } //Labels con base en código THT


                    if (respons.data[idx].tabla_recurso == "lms_cursos_presencial" && respons.data[idx].estatus == 7) {
                      respons.data[idx].boton_label = "VER DETALLES";
                    }

                    if (respons.data[idx].tabla_recurso == "lms_evaluaciones" || respons.data[idx].tabla_recurso == "lms_encuestas") {
                      if (respons.data[idx].estatus == 1 || respons.data[idx].estatus == 3 || respons.data[idx].estatus == 9 && parseInt(respons.data[idx].numero_intentos) > 0) {
                        respons.data[idx].boton_label = "APLICAR";
                      }
                    }

                    if (respons.data[idx].tabla_recurso == "lms_cursos_online") {
                      if (respons.data[idx].estatus == 1 || respons.data[idx].estatus == 3 || respons.data[idx].estatus == 9 && parseInt(respons.data[idx].numero_intentos) > 0) {
                        respons.data[idx].boton_label = "INGRESAR";
                      } else if (respons.data[idx].estatus == 8 && respons.data[idx].habilitar_calificar_ra == 1) {
                        respons.data[idx].boton_label = "VISUALIZAR";
                        respons.data[idx].habilitar_calificar = "1";
                        respons.data[idx].habilitar_calificar_ra = respons.data[idx].habilitar_calificar;
                        /*$('#modal_grade').modal({
                        	backdrop: 'static',
                        	keyboard: false
                        });
                        $('#modal_grade').on('hidden.bs.modal', function () {
                        	document.location.reload();
                        });
                        $('#modal_grade').modal();*/
                      }
                    }

                    respons.data[idx].visible = true; //Estatis

                    if (respons.data[idx].estatus === "1") {
                      respons.data[idx].estatus_evaluacion = "En curso";
                    } else if (respons.data[idx].estatus === "2") {
                      respons.data[idx].estatus_evaluacion = "Cancelado";
                    } else if (respons.data[idx].estatus === "3") {
                      respons.data[idx].estatus_evaluacion = "Reabierto";
                    } else if (respons.data[idx].estatus === "4") {
                      respons.data[idx].estatus_evaluacion = "Completo";
                      respons.data[idx].visible = false;
                    } else if (respons.data[idx].estatus === "5") {
                      respons.data[idx].estatus_evaluacion = "Incompleto";
                    } else if (respons.data[idx].estatus === "6") {
                      respons.data[idx].estatus_evaluacion = "Preinscripción";
                    } else if (respons.data[idx].estatus === "7") {
                      respons.data[idx].estatus_evaluacion = "Inscrito";
                    } else if (respons.data[idx].estatus === "8") {
                      respons.data[idx].estatus_evaluacion = "Aprobado";
                      respons.data[idx].boton_label = "VISUALIZAR";

                      if (respons.data[idx].tabla_recurso == 'lms_cursos_online') {
                        respons.data[idx].visible = true;
                      } else {
                        respons.data[idx].visible = false;
                      }
                    } else if (respons.data[idx].estatus === '9') {
                      respons.data[idx].estatus_evaluacion = "Reprobado";
                    } else if (respons.data[idx].estatus === '10') {
                      respons.data[idx].estatus_evaluacion = "No atendido";
                    } else if (respons.data[idx].estatus === '11') {
                      respons.data[idx].estatus_evaluacion = "Visitado";
                    } else if (respons.data[idx].estatus === '12') {
                      respons.data[idx].estatus_evaluacion = "No asistió";
                    } else {
                      respons.data[idx].estatus_evaluacion = "En espera";
                    }

                    if (parseInt(respons.data[idx].numero_intentos) > parseInt(respons.data[idx].limite_intentos)) {
                      respons.data[idx].lock = true;
                      respons.data[idx].leyend = 'Número de intentos alcanzado';
                    } else {
                      Object.keys(respons.data).forEach(function (idx2) {
                        if (respons.data[idx].dependencias != null) {
                          if (respons.data[idx2].propietario_recurso_k == respons.data[idx].dependencias) {
                            if (respons.data[idx2].propietario_recurso_k.estatus == '8' || respons.data[idx2].propietario_recurso_k.estatus == '4') {
                              respons.data[idx].lock = false;
                              respons.data[idx].leyend = '';
                            } else {
                              respons.data[idx].lock = true;
                              respons.data[idx].leyend = 'Disponible al concluir <b>' + respons.data[idx2].nombre + '</b>';

                              if (respons.data[idx].estatus == '1' || respons.data[idx].estatus == '8' || respons.data[idx].estatus == '4' || respons.data[idx].estatus == '9') {
                                respons.data[idx].lock = false;
                                respons.data[idx].leyend = '';
                              }
                            }
                          }
                        }
                      });
                    } //ICONos


                    if (respons.data[idx].tabla_recurso == 'lms_evaluaciones' && respons.data[idx].evaluacion_autodiagnostica == "1") {
                      respons.data[idx].icon = 'img/iconos_cursos/evaluacion.png';
                    } else if (respons.data[idx].tabla_recurso == 'lms_cursos_online') {
                      respons.data[idx].icon = 'img/iconos_cursos/scorm.png';
                    } else if (respons.data[idx].tabla_recurso == 'lms_encuestas') {
                      respons.data[idx].icon = 'img/iconos_cursos/encuesta.png';
                    } else if (respons.data[idx].tabla_recurso == 'lms_evaluaciones') {
                      respons.data[idx].icon = 'img/iconos_cursos/evaluacion.png';
                    } else if (respons.data[idx].tabla_recurso == 'lms_actividad') {
                      respons.data[idx].icon = 'img/iconos_cursos/actividad.png';
                    } else {
                      respons.data[idx].icon = 'img/iconos_cursos/presencial.png';
                    } //setTimeout(function () {


                    Ember.$('#loading').fadeOut('slow');
                    /*var slideout = new Slideout({
                            'panel': document.getElementById('panel'),
                            'menu': document.getElementById('menu'),
                            'side': 'left',
                             ///'padding': 1,
                              'tolerance': 70
                          });
                           document.querySelector('.js-slideout-toggle').addEventListener('click', function() {
                            slideout.toggle();
                          });
                            document.querySelector('.slideout-off').addEventListener('click', function() {
                            slideout.toggle();
                          });
                           document.querySelector('.menu').addEventListener('click', function(eve) {
                            if (eve.target.nodeName === 'A') { slideout.close(); }
                          });
                           $("#menu").removeAttr("style");*/
                    //}, 3000);
                  });
                  self.setEvaluacion(respons.data, self);
                },
                error: function error(e) {
                  console.log(e);
                }
              });
            },
            error: function error(e) {
              console.error(e);
            }
          });
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    didRender: function didRender() {
      var self = this;
      Ember.$('button[data-lock="false"]').removeAttr('disabled');
    },
    actions: {
      validaciones_pregunta_inicial: function validaciones_pregunta_inicial() {
        if (Ember.$('.carousel-item.active').data('obligatorio') == 1) {
          Ember.$('#btn_nxt').prop("disabled", true);
          setTimeout(function () {
            var checked = false;
            Ember.$('.carousel-item.active input').click(function () {
              Ember.$('.carousel-item.active input').each(function () {
                if (Ember.$(this).is(":checked")) {
                  checked = true;
                }
              });

              if (checked) {
                //if ($('.carousel-item.active input:checked').prop('checked') === true) {
                Ember.$('#btn_nxt').prop("disabled", false);
                Ember.$("#btn_end").prop("disabled", false);
              } else {
                Ember.$('#btn_nxt').prop("disabled", true);
                Ember.$("#btn_end").prop("disabled", true);
              }
            });
          }, 800);
          setTimeout(function () {
            Ember.$('.carousel-item.active textarea').each(function () {
              Ember.$(this).on('keyup mouseup change', function () {
                if (Ember.$(this).val() !== '' && Ember.$(this).val() != 0) {
                  Ember.$('#btn_nxt').prop("disabled", false);
                  Ember.$("#btn_end").prop("disabled", false);
                } else {
                  Ember.$('#btn_nxt').prop("disabled", true);
                  Ember.$("#btn_end").prop("disabled", true);
                }
              });
            });
            Ember.$('.carousel-item.active input[type=number]').each(function () {
              Ember.$(this).on('keyup mouseup change', function () {
                var pass = 0;
                Ember.$('.carousel-item.active input[type=number]').each(function () {
                  if (Ember.$(this).val() !== '' && Ember.$(this).val() != 0 && parseInt(Ember.$(this).val()) <= parseInt(Ember.$(this).attr('max')) && parseInt(Ember.$(this).val()) >= parseInt(Ember.$(this).attr('min'))) {
                    pass++;
                  }
                });

                if (pass == Ember.$('.carousel-item.active input[type=number]').length) {
                  Ember.$('#btn_nxt').prop("disabled", false);
                  Ember.$("#btn_end").prop("disabled", false);
                } else {
                  Ember.$('#btn_nxt').prop("disabled", true);
                  Ember.$("#btn_end").prop("disabled", true);
                }
              });
            });
            Ember.$('.carousel-item.active select').each(function () {
              Ember.$(this).change(function () {
                if (Ember.$(this).val() !== '') {
                  Ember.$('#btn_nxt').prop("disabled", false);
                  Ember.$("#btn_end").prop("disabled", false);
                }
              });
            });
          }, 800);
        }
      },
      scorm: function scorm() {
        Ember.$("#modal-scorm-saving").modal("show");
        Ember.$(".modal-dialog #ifr").attr("onload", "$('#modal-scorm-saving').modal('hide');");
        setTimeout(function () {
          Ember.$('#modal-scorm').modal('hide');
          Ember.$(".modal-dialog #ifr").attr("src", "");
        }, 1000);
      },
      evaluacion: function evaluacion(event) {
        var self = this;
        var propietario_recurso_k = Ember.$(event.target).attr('id');
        var noIntentos = Ember.$(event.target).data('intetos');
        var noIntentosActual = noIntentos;
        var evaluacion = this.get('evaluacion');
        var idxobjeto = Ember.$(event.target).data('idx');
        var objeto = evaluacion[idxobjeto];
        var url_page = window.location.href;
        var id = url_page.substring(url_page.lastIndexOf('/') + 1);

        if (idxobjeto == datosScoredUser.evaluation) {
          window.isHabilitarCalifRa = true;
        } else {
          window.isHabilitarCalifRa = false;
        } //window open post


        if (objeto.tabla_recurso == 'lms_cursos_online') {
          var url = constant.BASEURL + 'index.php/scorm/lanzarscorm';

          if (objeto.estatus == '8' || objeto.estatus == '4') {
            var form = '<form action="' + url + '" method="post" >' + '<input type="hidden" name="lms_scorm_k" value="' + objeto.scos + '" onclick="test()"/>' + '<input type="hidden" name="scorm_test" value="1" />' + '<input type="hidden" name="propietario_recurso_k" value="' + objeto.propietario_recurso_k + '" />' + //'<input type="hidden" name="propietario_recurso_k" value="'+objeto.noIntentosActual+'" />'+
            '</form>'; // App 

            if (window.hasOwnProperty("cordova") || (typeof cordova === "undefined" ? "undefined" : _typeof(cordova)) == "object" || !!window.cordova) {
              var options = "";

              if (device.platform == "Android") {
                options = 'hardwareback=no,location=yes,hidden=no,closebuttoncaption=Volver a mis cursos,hidenavigationbuttons=yes,hideurlbar=yes,footer=no,footercolor=' + window.color_theme;
              } else {
                options = 'hardwareback=no,location=no,hidden=no,closebuttoncaption=Volver a mis cursos,hidenavigationbuttons=yes,hideurlbar=yes,footer=false,footercolor=' + window.color_theme;
              }

              var pageContentUrl = 'data:text/html;base64,' + btoa('<html><head></head><body>' + form + '<script type="text/javascript">document.getElementById("formScorm").submit();</script></body></html>');
              var ref = cordova.InAppBrowser.open(pageContentUrl, '_blank', options);
              ref.addEventListener('loadstart', function (event) {});
              ref.addEventListener('loadstop', function (event) {});
              ref.addEventListener('exit', function (event) {});
            } else {
              // Web
              form = Ember.$(form);
              form.attr("target", '_blank');
              Ember.$('body').append(form);
              form.submit();
            }
          } else {
            var form = '<form id="formScorm" action="' + url + '" method="post" >' + '<input type="hidden" name="lms_scorm_k" value="' + objeto.scos + '" />' + '<input type="hidden" name="scorm_test" value="0" />' + '<input type="hidden" name="propietario_recurso_k" value="' + objeto.propietario_recurso_k + '" />' + //'<input type="hidden" name="propietario_recurso_k" value="'+objeto.noIntentosActual+'" />'+
            '</form>'; // App

            if (window.hasOwnProperty("cordova") || (typeof cordova === "undefined" ? "undefined" : _typeof(cordova)) == "object" || !!window.cordova) {
              var options = "";

              if (device.platform == "Android") {
                options = 'location=yes,hidden=no,closebuttoncaption=Volver a mis cursos,hidenavigationbuttons=yes,hideurlbar=yes,footer=no,footercolor=' + window.color_theme;
              } else {
                options = 'location=no,hidden=no,closebuttoncaption=Volver a mis cursos,hidenavigationbuttons=yes,hideurlbar=yes,footer=false,footercolor=' + window.color_theme;
              }

              var pageContentUrl = 'data:text/html;base64,' + btoa('<html><head></head><body>' + form + '<script type="text/javascript">document.getElementById("formScorm").submit();</script></body></html>');
              var ref = cordova.InAppBrowser.open(pageContentUrl, '_blank', options);
              ref.addEventListener('loadstart', function (event) {});
              ref.addEventListener('loadstop', function (event) {});
              ref.addEventListener('exit', function (event) {});
            } else {
              // Web
              form = Ember.$(form);
              form.attr("target", '_blank');
              Ember.$('body').append(form);
              form.submit();
            }
          } //$("#modal-scorm").modal("show");

        } else {
          window.buttoIngresar = Ember.$(event.target).attr('id');
          var data = 'propietario_recurso_k=' + objeto.propietario_recurso_k + '&intento_ra=' + objeto.intento_ra + '&grupo_k=' + id + '&usuario_k=' + '&numero_intentos=';
          var segmento = 'evaluacion';

          if (objeto.tabla_recurso == 'lms_encuestas') {
            segmento = 'encuesta';
          }

          Ember.$.ajax({
            url: constant.APIURL + 'lms/' + segmento + '/validaraplicar',
            type: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            data: data,
            xhrFields: {
              withCredentials: true
            },
            success: function success(response) {
              var r = JSON.parse(response);
              self.get('bus').trigger('setearReglas', r);
              Ember.$('#btn_nxt').attr('hidden', false);
              Ember.$('#btn_prv').attr('hidden', true);
              Ember.$('#btn_close').attr('hidden', true);
              sessionStorage.setItem('recurso_alumno_resultado_k', r.recurso_alumno_resultado_k);
              var preguntas = r.data.preguntas;
              Ember.$('#contadorPreguntas').html('Pregunta 1 de ' + preguntas.length);
              var html = '';
              var findOne = 0;

              if (preguntas.length == 1) {
                Ember.$('#btn_nxt').attr('hidden', true);
                Ember.$('#btn_end').removeAttr('hidden');
              }

              Object.keys(preguntas).forEach(function (idx) {
                html += '<div class="carousel-item" data-tabla="' + objeto.tabla_recurso + '" data-obligatorio=' + preguntas[idx].obligatoria + '>';
                html += '<div class="row">';
                html += '<div class="col-12">';
                html += '<br><h2 class="font-responsive" data-usuario="' + objeto.usuario_k + '" data-propietario="' + propietario_recurso_k + '" data-tipo="' + preguntas[idx].tipo_pregunta_k + '">' + preguntas[idx].pregunta + '</h2>';
                html += '</div>';
                html += '</div>'; // Tipo de variables traidas directamente del back
                //'LMS_PREGUNTAS_TIPO_RESPUESTA_SI_NO',              1 
                //'LMS_PREGUNTAS_TIPO_RESPUESTA_UNICA',              2 
                //'LMS_PREGUNTAS_TIPO_RESPUESTA_MULTIPLE',           3 
                //'LMS_PREGUNTAS_TIPO_RESPUESTA_LISTA_DESPLEGABLE',  4 
                //'LMS_PREGUNTAS_TIPO_RESPUESTA_RELACIONAR_COLUMNAS',5 
                //'LMS_PREGUNTAS_TIPO_RESPUESTA_PORCENTAJE',         6 
                //'LMS_PREGUNTAS_TIPO_RESPUESTA_PUNTUACION',         7 
                //'LMS_PREGUNTAS_TIPO_RESPUESTA_ABIERTA',            8 
                //'LMS_PREGUNTAS_TIPO_RESPUESTA_ORDENAR_RESPUESTA',  9 
                //'LMS_PREGUNTAS_TIPO_RESPUESTA_CALIFICAR_NIVELES',  10

                if (preguntas[idx].obligatoria == '1') {
                  Ember.$('#btn_nxt').prop("disabled", true);
                }

                if (preguntas[idx].tipo_pregunta_k == 1) {
                  Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta) {
                    html += '<div class="form-check">';
                    html += '<input class="form-check-input" type="radio" name="_' + idx + '" id="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '" value="' + preguntas[idx].respuestas[idx_respuesta].puntuacion + '" data-correcta="' + preguntas[idx].respuestas[idx_respuesta].correcta + '" data-orden="' + preguntas[idx].respuestas[idx_respuesta].orden + '" data-quest="' + preguntas[idx].pregunta_k + '"/>';
                    html += '<label class="form-check-label" for="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '">';
                    html += preguntas[idx].respuestas[idx_respuesta].descripcion;
                    html += '</label>';
                    html += '</div>';
                  });
                } else if (preguntas[idx].tipo_pregunta_k == 2) {
                  Object.keys(preguntas[idx].respuestas).forEach(function (idx2) {
                    html += '<div class="form-check">';
                    html += '<input class="form-check-input" type="radio" name="_' + idx + '" id="' + preguntas[idx].respuestas[idx2].respuesta_k + '" value="' + preguntas[idx].respuestas[idx2].puntuacion + '" data-correcta="' + preguntas[idx].respuestas[idx2].correcta + '" data-orden="' + preguntas[idx].respuestas[idx2].orden + '" data-quest="' + preguntas[idx].pregunta_k + '"/>';
                    html += '<label class="form-check-label" for="' + preguntas[idx].respuestas[idx2].respuesta_k + '">';
                    html += preguntas[idx].respuestas[idx2].descripcion;
                    html += '</label>';
                    html += '</div>';
                  });
                } else if (preguntas[idx].tipo_pregunta_k == 3) {
                  Object.keys(preguntas[idx].respuestas).forEach(function (idx2) {
                    html += '<div class="form-check">';
                    html += '<input class="form-check-input" type="checkbox" name="_' + idx + '" id="' + preguntas[idx].respuestas[idx2].respuesta_k + '" value="' + preguntas[idx].respuestas[idx2].puntuacion + '" data-correcta="' + preguntas[idx].respuestas[idx2].correcta + '" data-orden="' + preguntas[idx].respuestas[idx2].orden + '" data-quest="' + preguntas[idx].pregunta_k + '"/>';
                    html += '<label class="form-check-label" for="' + preguntas[idx].respuestas[idx2].respuesta_k + '">';
                    html += preguntas[idx].respuestas[idx2].descripcion;
                    html += '</label>';
                    html += '</div>';
                  });
                } else if (preguntas[idx].tipo_pregunta_k == 4) {
                  html += '<div class="form-group">';
                  html += '<select class="form-control" data-quest="' + preguntas[idx].pregunta_k + '">'; //html += '<select class="form-control" data-quest="'+preguntas[idx].pregunta_k+'">'

                  html += '<option class="form-check-input" data-orden=""></option>';
                  Object.keys(preguntas[idx].respuestas).forEach(function (idx2) {
                    html += '<option class="form-check-input" name="_' + idx + '" id="' + preguntas[idx].respuestas[idx2].respuesta_k + '" value="' + preguntas[idx].respuestas[idx2].puntuacion + '" data-correcta="' + preguntas[idx].respuestas[idx2].correcta + '" data-orden="' + preguntas[idx].respuestas[idx2].orden + '">' + preguntas[idx].respuestas[idx2].descripcion + '</option>';
                  });
                  html += '</select>';
                  html += '</div>';
                } else if (preguntas[idx].tipo_pregunta_k == 5) {
                  var left = [];
                  var right = [];
                  Object.keys(preguntas[idx].respuestas).forEach(function (idx2) {
                    if (parseInt(preguntas[idx].respuestas[idx2].orden, 10) % 2) {
                      left.push(preguntas[idx].respuestas[idx2]);
                    } else {
                      right.push(preguntas[idx].respuestas[idx2]);
                    }
                  });
                  html += '<div class="row">';
                  html += '<div class="col-6">';
                  html += '<ul data-quest="' + preguntas[idx].pregunta_k + '">';

                  for (var i = 0; i < left.length; i++) {
                    html += '<li id="' + left[i].respuesta_k + '" data-correcta="' + left[i].correcta + '" data-orden="' + left[i].orden + '"><i>' + (i + 1) + '</i><span>' + left[i].descripcion + '</span></li>';
                  }

                  html += '</ul>';
                  html += '</div>';
                  var letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
                  html += '<div class="col-6">';
                  html += '<ul>';

                  for (var i = 0; i < right.length; i++) {
                    html += '<li>';
                    html += '<i>' + letters[i] + '</i>' + ' ' + '<span>' + right[i].descripcion + '</span>';
                    html += '<select class="form-control select2" id="' + right[i].respuesta_k + '" data-orden=' + right[i].orden + '>';
                    html += '<option class="form-check-input" type="checkbox" id="" value="0" data-correcta="" data-orden=""></option>';

                    for (var j = 0; j < left.length; j++) {
                      html += '<option value="' + left[j].respuesta_k + '">';
                      html += '<span>' + left[j].descripcion + '</span>';
                      html += '</option>';
                    }

                    html += '</select>';
                    html += '</li>';
                  }

                  html += '</ul>';
                  html += '</div>';
                  html += '</div>';
                } else if (preguntas[idx].tipo_pregunta_k == 6) {
                  Object.keys(preguntas[idx].respuestas).forEach(function (idx2) {
                    html += '<div class="form-check">';
                    html += '<input max="100" min="0" class="form-check-input" type="number" name="_' + idx + '" id="' + preguntas[idx].respuestas[idx2].respuesta_k + '" value="' + preguntas[idx].respuestas[idx2].correcta + '" data-orden="' + preguntas[idx].respuestas[idx2].orden + '" data-quest="' + preguntas[idx].pregunta_k + '"/>';
                    html += '<label class="form-check-label" for="' + preguntas[idx].respuestas[idx2].respuesta_k + '">';
                    html += preguntas[idx].respuestas[idx2].descripcion;
                    html += '</label>';
                    html += '</div>';
                  });
                } else if (preguntas[idx].tipo_pregunta_k == 7) {
                  Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta) {
                    html += '<div class="form-check">';
                    html += '<label class="form-check-label margin_ud_10px" for="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '">';
                    html += preguntas[idx].respuestas[idx_respuesta].descripcion;
                    html += '<input class="form-control" max="' + preguntas[idx].respuestas[idx_respuesta].respuesta_correcta_k + '" min="1" type="number" name="_' + idx + '" id="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '" data-correcta="' + preguntas[idx].respuestas[idx_respuesta].correcta + '" value="' + preguntas[idx].respuestas[idx_respuesta].puntuacion * 10 + '" data-rc="' + preguntas[idx].respuestas[idx_respuesta].respuesta_correcta + '" data-orden="' + preguntas[idx].respuestas[idx_respuesta].orden + '"  data-quest="' + preguntas[idx].pregunta_k + '"/>';
                    html += '</label>';
                    html += '</div>';
                  });
                } else if (preguntas[idx].tipo_pregunta_k == 8) {
                  html += '<div class="form-check">';
                  html += '<textarea class="form-control" name="_' + idx + '" id="' + preguntas[idx].respuestas[0].respuesta_k + '" value="' + preguntas[idx].respuestas[0].descripcion + '" data-quest="' + preguntas[idx].pregunta_k + '"></textarea>';
                  html += '<label class="form-check-label">';
                  html += preguntas[idx].respuestas[0].descripcion;
                  html += '</label>';
                  html += '</div>';
                } else if (preguntas[idx].tipo_pregunta_k == 9) {
                  Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta) {
                    var contador = 1;
                    html += '<div class="row">';
                    html += '<div class="col-12 col-lg-8 margin_ud_10px">';
                    html += '<label class="form-check-label" for="customCheck1" data-respuesta="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '" data-orden="' + preguntas[idx].respuestas[idx_respuesta].orden + '">';
                    html += preguntas[idx].respuestas[idx_respuesta].descripcion;
                    html += '</label>';
                    html += '</div>';
                    html += '<div class="col-12 col-lg-4 margin_ud_10px">';
                    html += '<select class="form-control" data-quest="' + preguntas[idx].pregunta_k + '">';
                    html += '<option class="form-check-input value="0" data-correcta="" data-rc="" data-orden="">Seleccione una opción</option>';
                    Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta_ordenar) {
                      html += '<option class="form-check-input" id="' + preguntas[idx].respuestas[idx_respuesta_ordenar].respuesta_k + '" value="' + preguntas[idx].respuestas[idx_respuesta_ordenar].puntuacion + '" data-correcta="' + preguntas[idx].respuestas[idx_respuesta_ordenar].correcta + '" data-rc="' + preguntas[idx].respuestas[idx_respuesta].respuesta_correcta + '" data-orden="' + preguntas[idx].respuestas[idx_respuesta_ordenar].orden + '">' + contador++ + '</option>';
                    });
                    html += '</select>';
                    html += '</div>';
                    html += '</div>';
                  });
                } else {
                  html += 'Tipo de pregunta no implementado ' + preguntas[idx].tipo_pregunta_k;
                }

                html += '</div>';
              });
              var estadoPregunta = false;
              Ember.$('.bd-evaluacion-modal-lg').modal({
                backdrop: 'static',
                keyboard: false
              });
              Ember.$('#carouselQuestions').html(html);
              Ember.$('.carousel-item:first-child').addClass('active');
              var d = new Date();
              Ember.$('#date_time_start').html("Inicio: " + d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear() + " " + d.getHours() + ":" + ('0' + d.getMinutes()).slice(-2));
              Ember.$('.form-check-input').click(function () {
                Ember.$('#btn_nxt').prop("disabled", false);
                Ember.$("#btn_end").prop("disabled", false);
              });
              Ember.$('select.select2').select2({
                placeholder: 'Seleccione una opción',
                allowClear: true,
                width: '80%',
                selectOnClose: true,
                closeOnSelect: true
              });
              self.send('validaciones_pregunta_inicial');
            },
            error: function error(e) {
              console.error(e);
              Ember.$('#carouselQuestions').html('Estamos teniendo problemas tecnicos');
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});