define("comunidades-nafin/components/community-wall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    offset: 0,
    init: function init() {
      this._super.apply(this, arguments);

      var url_page = window.location.href.split("/");
      var id = url_page[url_page.length - 1];
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var self = this;
      var url_page = window.location.href.split("/");
      var id = url_page[url_page.length - 1];
      Ember.$.ajax({
        url: constant.APIURL + 'comunidad/listarElementosAsociadosInscripcion',
        type: 'POST',
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        data: 'params=%7B%22comunidad_k%22%3A%22' + id + '%22%7D',
        xhrFields: {
          withCredentials: true
        },
        success: function success(r) {
          var respons = JSON.parse(r);

          if (respons.data.length < 12) {
            Ember.$('#seeMoreCourses').hide();
          }

          if (constant.FIREBASE_SCORE != '') {
            Ember.$.ajax({
              url: constant.FIREBASE_SCORE,
              type: 'POST',
              data: {
                "method": "score_courses_resume"
              },
              success: function success(score_courses_resume) {
                respons.data.forEach(function (item, idx) {
                  if (respons.data[idx].nombre.length > 41) {
                    var nomb = respons.data[idx].nombre.toString();
                    respons.data[idx].nombre = nomb.substr(0, 40) + "...";
                  }

                  respons.data[idx].imagen_raprendizaje = solveImageURL(respons.data[idx].imagen_raprendizaje);

                  if (score_courses_resume.data) {
                    if (typeof score_courses_resume.data[respons.data[idx].ra_k] != 'undefined') {
                      try {
                        respons.data[idx].ranking_raprendizaje_score = Math.round(score_courses_resume.data[respons.data[idx].ra_k].rate);
                      } catch (e) {
                        respons.data[idx].ranking_raprendizaje_score = 0;
                        console.log(e);
                      }
                    } else {
                      respons.data[idx].ranking_raprendizaje_score = 0;
                    }
                  } else {
                    respons.data[idx].ranking_raprendizaje_score = 0;
                  }
                }); // Object.keys(respons.data).forEach(function(idx){
                //     respons.data[idx].imagen_raprendizaje = curso_imagen[respons.data[idx].grupo_k];
                // }); 

                respons.data = respons.data.sort(function (a, b) {
                  return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
                });
                self.set("allCourses", respons.data);
                var cursosV = [];

                for (var i = 0; i < 12; i++) {
                  if (respons.data[self.offset + i]) {
                    cursosV.push(respons.data[self.offset + i]);
                  }
                }

                self.offset += 12;
                setTimeout(function () {
                  Ember.$('#loading').fadeOut('slow');
                  lazyLoad.update();
                }, 3000);
                self.setComunidadEspecifica(cursosV);
                Ember.$("#curso_busqueda").on("keyup", function () {
                  var value = Ember.$(this).val().toLowerCase();
                  Ember.$("#cursos_busqueda #lms_ra").filter(function () {
                    Ember.$(this).toggle(Ember.$(this).text().toLowerCase().indexOf(value) > -1);
                  });
                });
              }
            });
          } else {
            respons.data = respons.data.sort(function (a, b) {
              return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
            });
            self.set("allCourses", respons.data); //respons.data = respons.data.sort((a, b) => { return (a.ranking_raprendizaje < b.ranking_raprendizaje) ? 1 : ((b.ranking_raprendizaje < a.ranking_raprendizaje) ? -1 : 0); });

            var cursosV = [];

            for (var i = 0; i < 12; i++) {
              if (respons.data[self.offset + i]) {
                cursosV.push(respons.data[self.offset + i]);
              }
            }

            self.offset += 12;
            setTimeout(function () {
              Ember.$('#loading').fadeOut('slow');
              lazyLoad.update();
            }, 3000);
            self.setComunidadEspecifica(cursosV);
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
      Ember.$.ajax({
        url: constant.APIURL + 'comunidad/listarMisComunidades',
        type: 'POST',
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        data: 'params=%7B%22comunidad_k%22%3A%22' + id + '%22%7D',
        xhrFields: {
          withCredentials: true
        },
        success: function success(r) {
          var respons = JSON.parse(r);

          if (respons.numFilas == 0) {
            document.location.href = window.location.href.split("/")[0] + "/#/comunidades";
          }

          respons.data[0].img = solveImageURL(respons.data[0].img);
          self.setCursosComunidad(respons.data);
          setTimeout(function () {
            Ember.$('#loading').fadeOut('slow');
          }, 5000);
        },
        error: function error(e) {
          console.log(e);
        }
      }); // HACER OTRA PETICION PARA CURSOS DE LA COMUNIDAD.

      Ember.$("#caruselActive").on("slide.bs.carousel", function (e) {
        var $e = Ember.$(e.relatedTarget);
        var idx = $e.index();
        var itemsPerSlide = 3;
        var totalItems = Ember.$("#carousel_communities .carousel-item").length;

        if (idx >= totalItems - (itemsPerSlide - 1)) {
          var it = itemsPerSlide - (totalItems - idx); //console.log(it)

          for (var i = 0; i < it; i++) {
            if (e.direction == "left") {
              Ember.$("#carousel_communities .carousel-item").eq(i).appendTo("#carousel_communities .carousel-inner");
            } else {
              Ember.$("#carousel_communities .carousel-item").eq(0).appendTo("#carousel_communities .carousel-inner");
            }
          }
        }
      });
    },

    /**
     *
     */
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var comunidad_boton_pregunta_a_la_comunidad_visible = false;
      var comunidades_boton_regresar_menu_anterior_visible = false;

      if (window.instanciaConfiguracion) {
        if (window.instanciaConfiguracion.comunidad_boton_pregunta_a_la_comunidad_visible) {
          if (window.instanciaConfiguracion.comunidad_boton_pregunta_a_la_comunidad_visible == true) {
            comunidad_boton_pregunta_a_la_comunidad_visible = true;
          }
        }
      }

      if (window.instanciaConfiguracion) {
        if (window.instanciaConfiguracion.comunidades_boton_regresar_menu_anterior_visible) {
          if (window.instanciaConfiguracion.comunidades_boton_regresar_menu_anterior_visible == true) {
            comunidades_boton_regresar_menu_anterior_visible = true;
          }
        }
      }

      this.set("comunidad_boton_pregunta_a_la_comunidad_visible", comunidad_boton_pregunta_a_la_comunidad_visible);
      this.set("comunidades_boton_regresar_menu_anterior_visible", comunidades_boton_regresar_menu_anterior_visible);
      var idioma = {};

      if (window.idioma) {
        idioma = window.idioma;
      }

      this.set("idioma", idioma);
    },
    numOfTimes: Ember.computed('times', function () {
      return [1, 2, 3, 4, 5];
    }),
    setComunidadEspecifica: function setComunidadEspecifica(r) {
      this.set('comunidad-especifica', r); // setTimeout(function () {
      //     $('#evaluaciones_dinamicas').remove();
      // }, 0);
    },
    setCursosComunidad: function setCursosComunidad(r) {
      this.set('cursos-comunidad', r);
      sessionStorage.setItem('seccion-anterior', "Comunidad " + r[0].nombre);
    },
    actions: {
      goCourse: function goCourse() {
        this._super.apply(this, arguments);

        var self = this;
        var url_page = window.location.href.split("/");
        var id = url_page[url_page.length - 1];
        Ember.$.ajax({
          url: constant.APIURL + 'comunidad/listarElementosAsociadosInscripcion',
          type: 'POST',
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          data: 'params=%7B%22comunidad_k%22%3A%22' + id + '%22%7D',
          xhrFields: {
            withCredentials: true
          },
          success: function success(r) {
            var respons = JSON.parse(r);

            if (constant.FIREBASE_SCORE != '') {
              Ember.$.ajax({
                url: constant.FIREBASE_SCORE,
                type: 'POST',
                data: {
                  "method": "score_courses_resume"
                },
                success: function success(score_courses_resume) {
                  respons.data.forEach(function (item, idx) {
                    if (respons.data[idx].nombre.length > 41) {
                      var nomb = respons.data[idx].nombre.toString();
                      respons.data[idx].nombre = nomb.substr(0, 40) + "...";
                    }

                    respons.data[idx].imagen_raprendizaje = solveImageURL(respons.data[idx].imagen_raprendizaje);

                    if (score_courses_resume.data) {
                      respons.data[idx].ranking_raprendizaje = score_courses_resume.data[respons.data[idx].ra_k];

                      if (respons.data[idx].ranking_raprendizaje) {
                        respons.data[idx].ranking_raprendizaje_score = Math.round(respons.data[idx].ranking_raprendizaje.rate);
                      } else {
                        respons.data[idx].ranking_raprendizaje_score = 0;
                      }
                    } else {
                      respons.data[idx].ranking_raprendizaje_score = 0;
                    }
                  }); // Object.keys(respons.data).forEach(function(idx){
                  //     respons.data[idx].imagen_raprendizaje = curso_imagen[respons.data[idx].grupo_k];
                  // }); 

                  respons.data = respons.data.sort(function (a, b) {
                    return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
                  });
                  self.setComunidadEspecifica(respons.data);
                }
              });
            } else {
              respons.data.forEach(function (item, idx) {
                respons.data[idx].imagen_raprendizaje = solveImageURL(respons.data[idx].imagen_raprendizaje);
              });
              respons.data = respons.data.sort(function (a, b) {
                return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
              });
              self.setComunidadEspecifica(respons.data);
            }

            Ember.$('#seeMoreCourses').hide();
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      goBack: function goBack() {
        history.back();
      },
      mostrarMasCursos: function mostrarMasCursos() {
        var allCourses = this.get("allCourses");
        var cursosV_temporal_array = [];

        var _self = this;

        var cuenta = 0;
        allCourses.forEach(function (_curso_record, _index, _cursos_lista_array) {
          cuenta += 1;
          /*
           *	Obtenemos la imagen de la ruta de aprendizaje, a patir de los siguienttes criterios:
           *
           *	Si existe una referencia a una imagen desde la relación local ( curso_imagen ), para
           *  la ruta de aprendizaje, se le da prioidad, en caso contario, se toma la imagen poveniente
           *  del servicio.
           * /
                       if (curso_imagen) {
                           if (curso_imagen[Ember.get(_curso_record, 'ra_k')]) {
                               Ember.set(_curso_record, 'imagen_raprendizaje', curso_imagen[Ember.get(_curso_record, 'ra_k')]);
                           }
                      }
          	/*
           * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
           */

          Ember.set(_curso_record, 'imagen_raprendizaje', solveImageURL(_curso_record.imagen_raprendizaje)); //var tabla_propietario = Ember.get(_curso_record, 'tabla_propietario');

          if (_index < _self.offset + 12) {
            if (_curso_record) {
              //if (tabla_propietario != 'evaluaciones_dinamicas') {
              cursosV_temporal_array.pushObject(_curso_record); //}
            }
          }
        });
        this.offset += 12;
        this.setComunidadEspecifica(cursosV_temporal_array);
        /*
        La forma en cómo esta solucionado el mostrarMasCursos() se debe cambiar ya que para que desaparezca dicho botón, 
        se tuvo que realizar una solución parcial debido a que no se tiene el suficiente control en el listado de los cursos.
                 */

        if (cuenta == cursosV_temporal_array.length + 1) {
          Ember.$('#seeMoreCourses').hide();
        } else if (cuenta == cursosV_temporal_array.length) {
          Ember.$('#seeMoreCourses').hide();
        }
      }
    }
  });

  _exports.default = _default;
});