define("comunidades-nafin/initializers/grammar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    Ember.$.ajax({
      url: constant.FIREBASE_DICCI,
      type: 'POST',
      data: {
        method: 'get_dictionary'
      },
      success: function success(response) {
        window.diccionario = [];
        Object.keys(response.data).forEach(function (key) {
          window.diccionario.push(key.toLowerCase());
        });
      },
      error: function error(_error) {
        console.log(_error);
      }
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});