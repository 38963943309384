define("comunidades-nafin/templates/cursos/curso-especifico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gvFTVFcp",
    "block": "{\"symbols\":[],\"statements\":[[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"css/cms.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"css/cursos.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[1,[20,\"menu-header\"],false],[0,\"\\n\"],[6,\"main\"],[10,\"id\",\"panel\"],[10,\"class\",\"panel\"],[8],[0,\"\\n\"],[14,\"loading\",[]],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n\\t\"],[6,\"div\"],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\\t\"],[6,\"div\"],[10,\"class\",\"row  hidden-standalone\"],[8],[0,\"\\n\\t\\t\\t\"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[6,\"button\"],[10,\"class\",\"btn btn-light\"],[10,\"type\",\"button\"],[3,\"action\",[[21,0,[]],\"goBack\"]],[8],[1,[26,\"fa-icon\",[\"angle-left\"],[[\"prefix\"],[\"fas\"]]],false],[9],[0,\" Regresar\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[1,[20,\"course-detail\"],false],[0,\"\\n\\t\\t\"],[1,[20,\"course-evaluation\"],false],[0,\"\\n\\t\\t\"],[1,[20,\"course-documents\"],false],[0,\"\\n\"],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[20,\"sub-footer\"],false],[0,\"\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":true}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/cursos/curso-especifico.hbs"
    }
  });

  _exports.default = _default;
});