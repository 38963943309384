define("comunidades-nafin/components/forum-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var self = this._super.apply(this, arguments);

      var url_page = window.location.href;
      var foro_id = url_page.substring(url_page.lastIndexOf('/') + 1);
      Ember.$.ajax({
        url: constant.APIURL + 'foro/listarPreguntasRecientes',
        type: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        xhrFields: {
          withCredentials: true
        },
        data: 'params={"comunidad_k":"' + foro_id + '"}',
        success: function success(r) {
          var respons = JSON.parse(r);
          Object.keys(respons.data).forEach(function (idx) {
            if (respons.data[idx].respuesta == null) {
              respons.data[idx].respuesta = 'Sin respuesta asociada';
            }

            if (respons.data[idx].pregunta === 'undefined' || respons.data[idx].pregunta == undefined) {
              respons.data[idx].pregunta = 'El backend manda undefined';
            }
          });

          if (respons.data == undefined || respons.data == null || respons.data == "") {
            self.withoutForum();
          } //self.setCursos(respons.data);
          //self.set('foros',respons.data);


          self.formatter(respons.data, self);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    didRender: function didRender() {
      Ember.$('.carousel-item:first-child').addClass('active');
      Ember.$('.carousel').carousel({
        interval: 5000
      });
    },
    //Dar formato deseado a mes y fechas
    formatter: function formatter(data, self) {
      Object.keys(data).forEach(function (idx) {
        var date_time = data[idx].timestamp_creacion.split(" ");
        var date = date_time[0].split('-');
        var month = date[1];
        data[idx].weekDay = self.timeStamp(date_time[0]);
        data[idx].monthFormath = self.monthFormatter(month);
        data[idx].dayFormath = date[2];
        data[idx].yearFormath = date[0]; //console.log(date[0]);
        //data[idx].formatt_hora = data[idx].hora.substr(0,5);
        //data[idx].formatt_hora_fin = data[idx].hora_fin.substr(0,5);
      });
      self.setMonth(data, self);
    },
    // Asignar el més en el que nos encontramos
    monthFormatter: function monthFormatter(month) {
      if (month === "01") {
        return "Enero";
      } else if (month === "02") {
        return "Febrero";
      } else if (month === "03") {
        return "Marzo";
      } else if (month === "04") {
        return "Abril";
      } else if (month === "05") {
        return "Mayo";
      } else if (month === "06") {
        return "Junio";
      } else if (month === "07") {
        return "Julio";
      } else if (month === "08") {
        return "Agosto";
      } else if (month === "09") {
        return "Septiembre";
      } else if (month === "10") {
        return "Octubre";
      } else if (month === "11") {
        return "Noviembre";
      } else {
        return "Diciembre";
      }
    },
    setMonth: function setMonth(data, self) {
      self.set('foros', data);
    },
    timeStamp: function timeStamp(date) {
      var array = date.split('-');
      var weeks = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
      var newDate = array[1] + "/" + array[2] + "/" + array[0];
      var temp = new Date(newDate);
      return weeks[temp.getDay()];
    },
    withoutForum: function withoutForum() {
      var html = '';
      html += '<div class="carousel-inner box_shado">';
      html += '<div class="carousel-item">';
      html += '<div class="card box_shado text-center relative">';
      html += '<div class="card-body">';
      html += '<br />';
      html += '<h2 class="card-text" >No hay foros asociados para esta comunidad.</h2>';
      html += '</div>';
      html += '</div>';
      html += '</div>';
      html += '</div>';
      Ember.$('#tarjeta_foro').html(html);
    },
    actions: {
      prevSlide: function prevSlide() {
        Ember.$('.carousel-inner.forums-cards').carousel('prev');
      },
      nextSlide: function nextSlide() {
        Ember.$('.carousel-inner.forums-cards').carousel('next');
      }
    }
  });

  _exports.default = _default;
});