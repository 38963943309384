define("comunidades-nafin/initializers/authentication", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var Session = Ember.Object.extend({
    isAuthenticated: false,
    isAdmin: false,
    isTutor: false,
    userData: {}
  }); // ROLES
  // Human Talent Admin					1
  // Administrador Nafin					2
  // Administrador Nafin Diagnostico		3
  // Administrador Nafin Notificaciones	4
  // Alumnos Nafin 						5
  // Tutor Nafin							6
  // Alumnos								7

  function initialize(application) {
    application.register('session:main', Session);
    application.inject('controller', 'session', 'session:main');
    application.inject('component', 'session', 'session:main');
    application.inject('route', 'session', 'session:main');
    Ember.$(document).ajaxComplete(function (e, r) {
      //console.log( "Triggered ajaxComplete handler.",response );
      var response = {};

      try {
        response = JSON.parse(r.responseText);
      } catch (ex) {} //console.log(ex);
      //console.log("===============",response,"==============")


      if (response.fatal) {
        /*$("#modal_login").modal('show');
        $('#loading').hide();
        $(".modal-backdrop.show").css("opacity","1");
        $(".modal-backdrop").addClass("backgroundLogin");
        $(".modal").addClass("backgroundLogin");
        $("#logoLogin").attr("src",$("#logoHeader").attr("src"));  	*/
        if (window.hasOwnProperty("cordova") || (typeof cordova === "undefined" ? "undefined" : _typeof(cordova)) == "object" || !!window.cordova) {
          Ember.$("#modal_login").modal('show');
          Ember.$('#loading').hide();
          Ember.$(".modal-backdrop.show").css("opacity", "1");
          Ember.$(".modal-backdrop").addClass("backgroundLogin");
          Ember.$(".modal").addClass("backgroundLogin");
          Ember.$("#logoLogin").attr("src", Ember.$("#logoHeader").attr("src"));
        } else {
          Ember.$('#modal-avisos').modal('show');
          Ember.$('.modal-backdrop').css({
            'opacity': '1'
          });
          Ember.$(".modal").addClass("backgroundLogin");
          Ember.$('#modal-avisos .modal-body').html('No ha iniciado sesión o esta ya ha finalizado, deberá iniciar sesión desde el entorno público en el menú "Iniciar Sesión"');
          Ember.$("#modal-avisos").on("hidden.bs.modal", function () {
            document.location.href = constant.LANDINGURL;
          });
          setTimeout(function () {
            document.location.href = constant.LANDINGURL;
          }, 2000);
        }
      }
    });
    Ember.$(document).ready(function () {
      //5 minutos para cada interaccion
      var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform) || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1; // Detects if device is in standalone mode

      var isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches; //() => ('standalone' in window.navigator) && (window.navigator.standalone);

      var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/); // Checks if should display install popup notification:
      //alert(isInStandaloneMode + " " + iOS + " " + isSafari);
      //if (iOS && !isInStandaloneMode && isSafari) {

      if (isInStandaloneMode) {
        var intervalID = window.setInterval(obtenerMensajes, 1000 * 60 * 5);
        setTimeout(function () {
          obtenerMensajes();
        }, 10000);

        function obtenerMensajes() {
          Ember.$.ajax({
            url: window.constant.APIURL + "notificaciones/listarnotificacionessinleer",
            type: "post",
            data: {
              tipo: 4
            },
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            xhrFields: {
              withCredentials: true
            },
            dataType: "json",
            success: function success(response) {
              if (response.success == true) {
                var body = JSON.parse(response.data.notificacion);
                toastr.options.closeButton = true;

                toastr.options.onclick = function () {
                  window.location.href = "/cursos";
                };

                toastr.info(body.body, '', {
                  timeOut: 10000
                });
              }
            },
            error: function error(e) {
              console.log(e);
            }
          });
        }
      }
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});