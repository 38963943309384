define("comunidades-nafin/initializers/socket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  var SocketObject = Ember.Object.extend({
    socket: {}
  });
  var SocketObjectChat = Ember.Object.extend({
    socket: {}
  });

  function initialize(application) {
    // application.inject('route', 'foo', 'service:foo');
    application.register('socket:main', SocketObject);
    application.inject('controller', 'socket', 'socket:main');
    application.inject('component', 'socket', 'socket:main');
    application.register('socket:main', SocketObject);
    application.inject('controller', 'chat', 'socket:main');
    application.inject('component', 'chat', 'socket:main');
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});