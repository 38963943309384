define("comunidades-nafin/components/video-tutorial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      $.ajax({
        url: constant.APIURL + 'usuario/p',
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        data: 'cl=mis_comunidades_general',
        success: function success(r) {
          r = JSON.parse(r);

          if (r.p.tutorial && r.p.tutorial != '') {
            $("#tutorialModalCenterBody").html('<iframe width="100%" height="300" src="' + constant.BASEURL + r.p.tutorial + '" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowfullscreen></iframe>');
            $('#tutorialModalCenter').modal('show');
          }
        }
      });
      $('.close').click(function (e) {
        e.preventDefault();
        $('#tutorialModalCenterBody').remove();
      });
      $('#tutorialModalCenter').on('hidden.bs.modal', function () {
        $('#tutorialModalCenterBody').remove();
      });
    },
    actions: {
      ocultar: function ocultar() {
        $.ajax({
          url: constant.APIURL + 'usuario/ocultartutorialporusuario',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          data: 'submodulo_k=mis_comunidades_general',
          success: function success(r) {
            $('#tutorialModalCenter').modal('hide');
            $('#tutorialModalCenterBody').remove();
          }
        });
      },
      cerrar: function cerrar() {
        $('#tutorialModalCenterBody').remove();
      }
    }
  });

  _exports.default = _default;
});