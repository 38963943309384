define("comunidades-nafin/components/news-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var self = this._super.apply(this, arguments);

      Ember.$.ajax({
        url: constant.APIURL + 'noticia/listarNoticiasRelevantes',
        type: 'POST',
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        data: 'start=0&limit=2&user=-1',
        xhrFields: {
          withCredentials: true
        },
        success: function success(r) {
          var data = JSON.parse(r);
          /*
           *  Aseguramos que las imagenes contengan un path absoluto.
           */

          var noticias_array = data.data.map(function (_record) {
            if (_record.imagen_crop.indexOf(constant.BASEURL) == -1) {
              _record.imagen = solveImageURL(_record.imagen_crop);
            }

            var tmp = document.createElement("div");
            tmp.innerHTML = _record.cuerpo;
            _record.descripcion = tmp.textContent || tmp.innerText;
            return _record;
          });
          self.set('news-box', noticias_array);
          self.formatter(data.data, self);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    formatter: function formatter(data, self) {
      Object.keys(data).forEach(function (idx) {
        var date_time = data[idx].timestamp_creacion.split(" ");
        var date = date_time[0].split('-');
        var month = date[1];
        data[idx].weekDay = self.timeStamp(date_time[0]);
        data[idx].monthFormath = self.monthFormatter(month);
        data[idx].dayFormath = date[2];
        data[idx].yearFormath = date[0];
      });
      self.setNews(data, self);
    },
    monthFormatter: function monthFormatter(month) {
      if (month === "01") {
        return "Enero";
      } else if (month === "02") {
        return "Febrero";
      } else if (month === "03") {
        return "Marzo";
      } else if (month === "04") {
        return "Abril";
      } else if (month === "05") {
        return "Mayo";
      } else if (month === "06") {
        return "Junio";
      } else if (month === "07") {
        return "Julio";
      } else if (month === "08") {
        return "Agosto";
      } else if (month === "09") {
        return "Septiembre";
      } else if (month === "10") {
        return "Octubre";
      } else if (month === "11") {
        return "Noviembre";
      } else {
        return "Diciembre";
      }
    },
    timeStamp: function timeStamp(date) {
      var array = date.split('-');
      var weeks = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
      var newDate = array[1] + "/" + array[2] + "/" + array[0];
      var temp = new Date(newDate);
      return weeks[temp.getDay()];
    },
    setNews: function setNews(r, self) {
      self.set('news-box', r);
    },
    actions: {
      noticiaEspecifica: function noticiaEspecifica(path) {
        document.location = "#/noticias/?noticia_k=" + path; //this.get('router').transitionTo('noticias');
      }
    }
  });

  _exports.default = _default;
});