define("comunidades-nafin/components/courses-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var self = this._super.apply(this, arguments);

      Ember.$.ajax({
        url: constant.APIURL + 'comunidad/listarMisInscripcionesRa',
        type: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: 'start=20&limit=20&user=false&params={"agrupar":"agrupar_por_cursos"}',
        xhrFields: {
          withCredentials: true
        },
        success: function success(r) {
          var respons = JSON.parse(r);

          if (constant.FIREBASE_SCORE != '') {
            Ember.$.ajax({
              url: constant.FIREBASE_SCORE,
              type: 'POST',
              data: {
                "method": "score_courses_resume"
              },
              success: function success(score_courses_resume) {
                //Se le agrega la votación desde Firebase
                respons.data.forEach(function (item, idx) {
                  respons.data[idx].imagen_raprendizaje = solveImageURL(respons.data[idx].imagen_raprendizaje);

                  if (score_courses_resume.data) {
                    //respons.data[idx].ranking_raprendizaje = score_courses_resume.data[respons.data[idx].ra_k];
                    if (typeof score_courses_resume.data[respons.data[idx].ra_k] != 'undefined' && score_courses_resume.data[respons.data[idx].ra_k] != null) {
                      respons.data[idx].ranking_raprendizaje_score = Math.round(score_courses_resume.data[respons.data[idx].ra_k].rate);
                    } else {
                      respons.data[idx].ranking_raprendizaje_score = 0;
                    }
                  } else {
                    respons.data[idx].ranking_raprendizaje_score = 0;
                  }
                }); //Se ordenan por ranking

                respons.data = respons.data.sort(function (b, a) {
                  return a.ranking_raprendizaje_score > b.ranking_raprendizaje_score ? 1 : b.ranking_raprendizaje_score > a.ranking_raprendizaje_score ? -1 : 0;
                });
                self.set("curso", respons.data);
                setTimeout(function () {
                  lazyLoad.update();
                }, 1000);
                setTimeout(function () {
                  Ember.$('#carousel_courses').carousel({
                    interval: 5000
                  });
                  window.loadnewcarousel();
                }, 0);
              }
            });
          } else {
            respons.data.forEach(function (item, idx) {
              respons.data[idx].imagen_raprendizaje = solveImageURL(respons.data[idx].imagen_raprendizaje);
            });
            respons.data = respons.data.sort(function (a, b) {
              return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
            });
            self.set("curso", respons.data);
            setTimeout(function () {
              lazyLoad.update();
            }, 1000);
            setTimeout(function () {
              Ember.$('#carousel_courses').carousel({
                interval: 5000
              });
              window.loadnewcarousel();
            }, 0);
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    didRender: function didRender() {},
    numOfTimes: Ember.computed('times', function () {
      return [1, 2, 3, 4, 5];
    }),
    setCursos: function setCursos(r) {
      this.set('curso', r);
    },
    actions: {
      cursoEspecifico: function cursoEspecifico(path) {
        this.get('router').transitionTo('cursos/curso-especifico', path);
      }
    }
  });

  _exports.default = _default;
});