define("comunidades-nafin/components/registro-components/nafin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    actions: {
      cancelarRegistro: function cancelarRegistro() {
        this.get('router').transitionTo('inicio');
      }
    }
  });

  _exports.default = _default;
});