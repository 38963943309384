define("comunidades-nafin/templates/components/menu-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qBb8pEgL",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n \"],[6,\"div\"],[10,\"class\",\"py-1 text-white menu-mobile d-none_ show-standalone\"],[8],[0,\"\\n      \"],[6,\"div\"],[10,\"class\",\"\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"row\"],[10,\"align\",\"\"],[8],[0,\" \\n          \"],[6,\"div\"],[10,\"class\",\"col-12 \"],[10,\"align\",\"center\"],[8],[0,\"\\n\\n              \"],[6,\"span\"],[10,\"class\",\"text-white option-menu-mobile js-slideout-toggle\"],[8],[0,\"\\n                \"],[6,\"i\"],[10,\"class\",\"fa fa-bars \"],[8],[9],[0,\"\\n                \"],[6,\"br\"],[8],[9],[6,\"span\"],[10,\"class\",\"font-responsive\"],[8],[0,\"Menu\"],[9],[0,\"\\n              \"],[9],[0,\"\\n              \"],[6,\"a\"],[10,\"class\",\"text-white option-menu-mobile\"],[10,\"href\",\"#\"],[3,\"action\",[[21,0,[]],\"goCourses\"]],[8],[0,\"\\n                \"],[6,\"i\"],[10,\"class\",\"fa fa-book\"],[8],[9],[0,\"\\n                \"],[6,\"br\"],[8],[9],[6,\"span\"],[10,\"class\",\"font-responsive text-cursos\"],[10,\"id\",\"inicio-menu-mobile\"],[8],[0,\"Cursos\"],[9],[0,\"\\n              \"],[9],[0,\"\\n              \"],[6,\"a\"],[10,\"class\",\"text-white option-menu-mobile\"],[10,\"href\",\"#\"],[3,\"action\",[[21,0,[]],\"goWebinars\"]],[8],[0,\"\\n                \"],[6,\"i\"],[10,\"class\",\"fa fa-play\"],[8],[9],[0,\"\\n                \"],[6,\"br\"],[8],[9],[6,\"span\"],[10,\"class\",\"font-responsive text-webinar\"],[8],[0,\"Webinars\"],[9],[0,\"\\n              \"],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"  \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/menu-mobile.hbs"
    }
  });

  _exports.default = _default;
});