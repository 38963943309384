define("comunidades-nafin/routes/cursos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    offset: 0,
    beforeModel: function beforeModel() {
      var promise = new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(this, function () {
          resolve("ok");
        }, 0);
      });
      return promise;
    },
    setupController: function setupController(controller, model) {
      setTimeout(function () {
        Ember.$(".text-cursos").addClass("selected-option-menu");
        Ember.$('#searchInputCursos').on('keyup', function () {
          if (Ember.$('#searchInputCursos').val().length > 3 || Ember.$('#searchInputCursos').val().length < 2) {
            Ember.$('.dropdown > button').text('Selecciona una opción');
            controller.send('search');
          }
        });
      }, 2000);
    },
    drawStars: function drawStars(starts) {
      var html = ''; //curso[idx].ranking_raprendizaje

      if (starts > 0.999 && starts < 1.2999) {
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
      } else if (starts > 1.2999 && starts < 1.699) {
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star-half-alt"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
      } else if (starts > 1.699 && starts < 2.299) {
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
      } else if (starts > 2.299 && starts < 2.699) {
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star-half-alt"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
      } else if (starts > 2.699 && starts < 3.299) {
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
      } else if (starts > 3.299 && starts < 3.699) {
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star-half-alt"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
      } else if (starts > 3.699 && starts < 4.299) {
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
      } else if (starts > 4.299 && starts < 4.699) {
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star-half-alt"></i></li>';
      } else if (starts > 4.699) {
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
      } else {
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
      }

      return html;
    },
    round: function round(number) {
      var result = 0;

      if (number > 0.999 && number < 1.2999) {
        result = 1;
      } else if (number > 1.2999 && number < 1.699) {
        result = 1.5;
      } else if (number > 1.699 && number < 2.299) {
        result = 2;
      } else if (number > 2.299 && number < 2.699) {
        result = 2.5;
      } else if (number > 2.699 && number < 3.299) {
        result = 3;
      } else if (number > 3.299 && number < 3.699) {
        result = 3.5;
      } else if (number > 3.699 && number < 4.299) {
        result = 4;
      } else if (number > 4.299 && number < 4.699) {
        result = 4.5;
      } else if (number > 4.699) {
        result = 5;
      }

      return result;
    },
    actions: {
      goBack: function goBack() {
        document.location.href = '/cursos';
      },
      eliminarBusqueda: function eliminarBusqueda() {
        Ember.$('#searchInputCursos').val('');
        var self = this;
        Ember.$.ajax({
          url: constant.APIURL + 'comunidad/listarMisInscripcionesRa',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: 'params= {"agrupar":"agrupar_por_cursos","nombre":""}',
          success: function success(response) {
            var r = JSON.parse(response);
            r.data = r.data.sort(function (a, b) {
              return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
            });
            var curso = r.data;
            var html = '';
            var contador = 0;
            html += '<div class="row">';

            if (constant.FIREBASE_SCORE != '') {
              //Vamos por la calificación de los cursos
              Ember.$.ajax({
                url: constant.FIREBASE_SCORE,
                type: 'POST',
                data: {
                  "method": "score_courses_resume"
                },
                success: function success(score_courses_resume) {
                  //Se le agrega la votación desde Firebase
                  r.data.forEach(function (item, idx) {
                    try {
                      //r.data[idx].imagen_raprendizaje = curso_imagen[r.data[idx].grupo_k];
                      if (score_courses_resume.data) {
                        if (typeof score_courses_resume.data[r.data[idx].ra_k] != 'undefined') {
                          r.data[idx].ranking_raprendizaje = score_courses_resume.data[r.data[idx].ra_k].rate;
                        } else {
                          r.data[idx].ranking_raprendizaje = 0;
                        }
                      } else {
                        r.data[idx].ranking_raprendizaje = 0;
                      }
                    } catch (e) {
                      console.log(e);
                    }
                  });
                  Object.keys(curso).forEach(function (idx) {
                    /*
                     *  Obtenemos la imagen de la ruta de aprendizaje, a patir de los siguienttes criterios:
                     *
                     *  Si existe una referencia a una imagen desde la relación local ( curso_imagen ), para
                     *  la ruta de aprendizaje, se le da prioidad, en caso contario, se toma la imagen poveniente
                     *  del servicio.
                     * /
                    if (curso_imagen) {
                    if (curso_imagen[curso[idx].ra_k]) {
                    curso[idx].imagen_raprendizaje = curso_imagen[curso[idx].ra_k];
                    }
                    }
                     /*
                     * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
                     */
                    curso[idx].imagen_raprendizaje = solveImageURL(curso[idx].imagen_raprendizaje);
                    html += '<div class="col-xl-3 col-sm-6 col-12 margin_top_10">';
                    html += '<div class="card text-white text-center">';
                    html += '<a class="white_link" href="cursos/' + curso[idx].ra_k + '">';
                    html += '<img class="card-img" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image"/>';
                    html += '<div class="icon_approved">';

                    if (curso[idx].estatus === "8") {
                      html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" width="50" height="50" />';
                    }

                    html += '</div>';
                    html += '<div class="card-img-overlay img_gradient" style="top: auto; padding: 0px;">';
                    html += '<div class="row text-center">';
                    html += '<div class="col-12">';
                    html += '<ul class="horizontal-list no_padding" id="' + contador++ + '">';
                    html += self.drawStars(curso[idx].ranking_raprendizaje);
                    html += '</ul>';
                    html += '</div>';
                    html += '</div>';
                    html += '<p class="card-text ">' + curso[idx].nombre + '</p>';
                    html += '<p class="card-text uppercase"><small>' + curso[idx].nombre_comunidad + '</small></p>';
                    html += '</div>';
                    html += '<div class="icon_approved">';
                    html += '<img class="image_remove ' + curso[idx].estatus + '" src="' + curso[idx].imagen + '" width="50" height="50" hidden/>';
                    html += '</div>';
                    html += '</a>';
                    html += '</div>';
                    html += '<div class="row">';
                    html += '<div class="col-10">';
                    html += '<div class="progress margin_top_5">';
                    html += '<div class="progress-bar progress-bar-theme" role="progressbar" style=width:' + curso[idx].porcentaje_avance + '% aria-valuenow=' + curso[idx].porcentaje_avance;
                    html += 'aria-valuemin=' + curso[idx].porcentaje_avance + ' aria-valuemax=' + curso[idx].porcentaje_avance + '></div>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div class="col-2">';
                    html += '<p class="number_bar text-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>';
                    html += '</div>';
                    html += '</div>';
                    html += '</div>';
                  });
                  html += '<div class="col-12"><center><hr/></center></div>';
                  Ember.$('#searchContainer').html(html);
                }
              });
            } else {
              Object.keys(curso).forEach(function (idx) {
                /*
                 *  Obtenemos la imagen de la ruta de aprendizaje, a patir de los siguienttes criterios:
                 *
                 *  Si existe una referencia a una imagen desde la relación local ( curso_imagen ), para
                 *  la ruta de aprendizaje, se le da prioidad, en caso contario, se toma la imagen poveniente
                 *  del servicio.
                 * /
                if (curso_imagen) {
                if (curso_imagen[curso[idx].ra_k]) {
                curso[idx].imagen_raprendizaje = curso_imagen[curso[idx].ra_k];
                }
                }
                 /*
                 * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
                 */
                curso[idx].imagen_raprendizaje = solveImageURL(curso[idx].imagen_raprendizaje);
                html += '<div class="col-xl-3 col-sm-6 col-12 margin_top_10">';
                html += '<div class="card text-white text-center">';
                html += '<a class="white_link" href="cursos/' + curso[idx].ra_k + '">';
                html += '<img class="card-img" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image"/>';
                html += '<div class="icon_approved">';

                if (curso[idx].estatus === "8") {
                  html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" width="50" height="50" />';
                }

                html += '</div>';
                html += '<div class="card-img-overlay img_gradient" style="top: auto; padding: 0px;">';
                html += '<div class="row text-center">';
                html += '<div class="col-12">';
                html += '<ul class="horizontal-list no_padding" id="' + contador++ + '">';
                html += self.drawStars(curso[idx].ranking_raprendizaje);
                html += '</ul>';
                html += '</div>';
                html += '</div>';
                html += '<p class="card-text ">' + curso[idx].nombre + '</p>';
                html += '<p class="card-text uppercase"><small>' + curso[idx].nombre_comunidad + '</small></p>';
                html += '</div>';
                html += '<div class="icon_approved">';
                html += '<img class="image_remove ' + curso[idx].estatus + '" src="' + curso[idx].imagen + '" width="50" height="50" hidden/>';
                html += '</div>';
                html += '</a>';
                html += '</div>';
                html += '<div class="row">';
                html += '<div class="col-10">';
                html += '<div class="progress margin_top_5">';
                html += '<div class="progress-bar progress-bar-theme" role="progressbar" style=width:' + curso[idx].porcentaje_avance + '% aria-valuenow=' + curso[idx].porcentaje_avance;
                html += 'aria-valuemin=' + curso[idx].porcentaje_avance + ' aria-valuemax=' + curso[idx].porcentaje_avance + '></div>';
                html += '</div>';
                html += '</div>';
                html += '<div class="col-2">';
                html += '<p class="number_bar text-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>';
                html += '</div>';
                html += '</div>';
                html += '</div>';
              });
              html += '<div class="col-12"><center><hr/></center></div>';
              Ember.$('#searchContainer').html(html);
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      search: function search() {
        var parametro = Ember.$('#searchInputCursos').val();
        var self = this;
        Ember.$.ajax({
          url: constant.APIURL + 'comunidad/listarMisInscripcionesRa',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: 'params= {"agrupar":"agrupar_por_cursos","nombre":"' + parametro + '"}',
          success: function success(response) {
            var r = JSON.parse(response);
            r.data = r.data.sort(function (a, b) {
              return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
            });
            var curso = r.data;
            var html = '';
            var contador = 0;
            html += '<div class="col-12 hidden-standalone"><center><h2>Resultados de Busqueda<h2></center></div><div class="row">';

            if (constant.FIREBASE_SCORE != '') {
              //Vamos por la calificación de los cursos
              Ember.$.ajax({
                url: constant.FIREBASE_SCORE,
                type: 'POST',
                data: {
                  "method": "score_courses_resume"
                },
                success: function success(score_courses_resume) {
                  //Se le agrega la votación desde Firebase
                  r.data.forEach(function (item, idx) {
                    try {
                      //r.data[idx].imagen_raprendizaje = curso_imagen[r.data[idx].grupo_k];
                      if (score_courses_resume.data) {
                        if (typeof score_courses_resume.data[r.data[idx].ra_k] != 'undefined') {
                          //console.log(score_courses_resume.data[r.data[idx].ra_k].rate);
                          r.data[idx].ranking_raprendizaje = score_courses_resume.data[r.data[idx].ra_k].rate;
                        } else {
                          r.data[idx].ranking_raprendizaje = 0;
                        }
                      } else {
                        r.data[idx].ranking_raprendizaje = 0;
                      }
                    } catch (e) {
                      console.log(e);
                    }
                  });
                  Object.keys(curso).forEach(function (idx) {
                    /* if (curso_imagen) {
                    	if (curso_imagen[curso[idx].ra_k]) {
                    		curso[idx].imagen_raprendizaje = curso_imagen[curso[idx].ra_k];
                    	}
                    } */
                    curso[idx].imagen_raprendizaje = solveImageURL(curso[idx].imagen_raprendizaje);
                    var nomb = curso[idx].nombre.toString();
                    curso[idx].nombre_completo = nomb;

                    if (curso[idx].nombre.length > 50) {
                      curso[idx].nombre = nomb.substr(0, 47) + "...";
                    }

                    html += '<div class="col-xl-3 col-sm-6 col-12 margin_top_10 hidden-standalone">';
                    html += '<div class="card text-white text-center">';
                    html += '<a class="white_link" href="cursos/' + curso[idx].ra_k + '">';
                    html += '<img class="card-img" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image"/>';
                    html += '<div class="icon_approved">';

                    if (curso[idx].estatus === "8") {
                      html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" width="50" height="50" />';
                    }

                    html += '</div>';
                    html += '<div class="card-img-overlay img_gradient" style="top: auto; padding: 0px;">';
                    html += '<div class="row text-center">';
                    html += '<div class="col-12">';
                    html += '<ul class="horizontal-list no_padding" id="' + contador++ + '">';
                    html += self.drawStars(curso[idx].ranking_raprendizaje);
                    html += '</ul>';
                    html += '</div>';
                    html += '</div>';
                    html += '<p class="card-text ">' + curso[idx].nombre + '</p>';
                    html += '<p class="card-text uppercase"><small>' + curso[idx].nombre_comunidad + '</small></p>';
                    html += '</div>';
                    html += '<div class="icon_approved">';
                    html += '<img class="image_remove ' + curso[idx].estatus + '" src="' + curso[idx].imagen + '" width="50" height="50" hidden/>';
                    html += '</div>';
                    html += '</a>';
                    html += '</div>';
                    html += '<div class="row">';
                    html += '<div class="col-10">';
                    html += '<div class="progress margin_top_5">';
                    html += '<div class="progress-bar progress-bar-theme" role="progressbar" style=width:' + curso[idx].porcentaje_avance + '% aria-valuenow=' + curso[idx].porcentaje_avance;
                    html += 'aria-valuemin=' + curso[idx].porcentaje_avance + ' aria-valuemax=' + curso[idx].porcentaje_avance + '></div>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div class="col-2">';
                    html += '<p class="number_bar text-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>';
                    html += '</div>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div class="col-sm-12 col-md-6 col-lg-4 d-none show-standalone">';
                    html += '<a class="" href="cursos/' + curso[idx].ra_k + '">';
                    html += '<div class=" row">';
                    html += '<div class="col-4" align="center">';
                    html += '<img class=" lazy" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image">';

                    if (curso[idx].estatus === "8") {
                      html += '<div class="curso_completado" style="">';
                      html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" style="" hidden/>';
                      html += '</div>';
                    }

                    html += '</div>';
                    html += '<div class="col-8">';
                    html += '<div class="" style="font-size:0.75rem">';
                    html += curso[idx].nombre_completo;
                    html += '</div>';
                    html += '<div class="row">';
                    html += '<div class="col-9">';
                    html += '<div class="progress margin_top_5 progress-bar-client-theme">';
                    html += '<div class="progress-bar progress-bar-theme" role="progressbar" style="width:' + curso[idx].porcentaje_avance + '%" aria-valuenow="' + curso[idx].porcentaje_avance + '" aria-valuemin="' + curso[idx].porcentaje_avance + '" aria-valuemax="' + curso[idx].porcentaje_avance + '"></div>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div class="col-2">';
                    html += '<p class="number_bar number-bar-client-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>';
                    html += '</div>';
                    html += '</div>';
                    html += '<ul class="horizontal-list no_padding cursos-stars pull-right">';
                    html += self.drawStars(curso[idx].ranking_raprendizaje);
                    html += '</ul>';
                    html += '</div>';
                    html += '</div>';
                    html += '</a>';
                    html += '<hr>';
                    html += '</div>';
                  });
                  html += '<div class="col-12"><center><hr/></center></div>';
                  Ember.$('#searchContainer').html(html);
                }
              });
            } else {
              Object.keys(curso).forEach(function (idx) {
                /* if (curso_imagen) {
                	if (curso_imagen[curso[idx].ra_k]) {
                		curso[idx].imagen_raprendizaje = curso_imagen[curso[idx].ra_k];
                	}
                } */
                curso[idx].imagen_raprendizaje = solveImageURL(curso[idx].imagen_raprendizaje);
                var nomb = curso[idx].nombre.toString();
                curso[idx].nombre_completo = nomb;

                if (curso[idx].nombre.length > 50) {
                  curso[idx].nombre = nomb.substr(0, 47) + "...";
                }

                html += '<div class="col-xl-3 col-sm-6 col-12 margin_top_10 hidden-standalone">';
                html += '<div class="card text-white text-center">';
                html += '<a class="white_link" href="cursos/' + curso[idx].ra_k + '">';
                html += '<img class="card-img" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image"/>';
                html += '<div class="icon_approved">';

                if (curso[idx].estatus === "8") {
                  html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" width="50" height="50" />';
                }

                html += '</div>';
                html += '<div class="card-img-overlay img_gradient" style="top: auto; padding: 0px;">';
                html += '<div class="row text-center">';
                html += '<div class="col-12">';
                html += '<ul class="horizontal-list no_padding" id="' + contador++ + '">';
                html += self.drawStars(curso[idx].ranking_raprendizaje);
                html += '</ul>';
                html += '</div>';
                html += '</div>';
                html += '<p class="card-text ">' + curso[idx].nombre + '</p>';
                html += '<p class="card-text uppercase"><small>' + curso[idx].nombre_comunidad + '</small></p>';
                html += '</div>';
                html += '<div class="icon_approved">';
                html += '<img class="image_remove ' + curso[idx].estatus + '" src="' + curso[idx].imagen + '" width="50" height="50" hidden/>';
                html += '</div>';
                html += '</a>';
                html += '</div>';
                html += '<div class="row">';
                html += '<div class="col-10">';
                html += '<div class="progress margin_top_5">';
                html += '<div class="progress-bar progress-bar-theme" role="progressbar" style=width:' + curso[idx].porcentaje_avance + '% aria-valuenow=' + curso[idx].porcentaje_avance;
                html += 'aria-valuemin=' + curso[idx].porcentaje_avance + ' aria-valuemax=' + curso[idx].porcentaje_avance + '></div>';
                html += '</div>';
                html += '</div>';
                html += '<div class="col-2">';
                html += '<p class="number_bar text-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>';
                html += '</div>';
                html += '</div>';
                html += '</div>';
                html += '<div class="col-sm-12 col-md-6 col-lg-4 d-none show-standalone">';
                html += '<a class="" href="cursos/' + curso[idx].ra_k + '">';
                html += '<div class=" row">';
                html += '<div class="col-4" align="center">';
                html += '<img class=" lazy" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image">';

                if (curso[idx].estatus === "8") {
                  html += '<div class="curso_completado" style="">';
                  html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" style="" hidden/>';
                  html += '</div>';
                }

                html += '</div>';
                html += '<div class="col-8">';
                html += '<div class="" style="font-size:0.75rem">';
                html += curso[idx].nombre_completo;
                html += '</div>';
                html += '<div class="row">';
                html += '<div class="col-9">';
                html += '<div class="progress margin_top_5 progress-bar-client-theme">';
                html += '<div class="progress-bar progress-bar-theme" role="progressbar" style="width:' + curso[idx].porcentaje_avance + '%" aria-valuenow="' + curso[idx].porcentaje_avance + '" aria-valuemin="' + curso[idx].porcentaje_avance + '" aria-valuemax="' + curso[idx].porcentaje_avance + '"></div>';
                html += '</div>';
                html += '</div>';
                html += '<div class="col-2">';
                html += '<p class="number_bar number-bar-client-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>';
                html += '</div>';
                html += '</div>';
                html += '<ul class="horizontal-list no_padding cursos-stars pull-right">';
                html += self.drawStars(curso[idx].ranking_raprendizaje);
                html += '</ul>';
                html += '</div>';
                html += '</div>';
                html += '</a>';
                html += '<hr>';
                html += '</div>';
              });
              html += '<div class="col-12"><center><hr/></center></div>';
              Ember.$('#searchContainer').html(html);
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      sortProgreso: function sortProgreso() {
        var self = this;
        var controller = self.controller;
        controller.set('comunidad', false);
        controller.set('valorados', false);
        controller.set('recien', false);
        controller.set('progreso', true);
        controller.set('nombre', false);
        Ember.$('.dropdown > button').text('Progreso');
        Ember.$('.dropdown .dropdown-menu a').show();
        Ember.$.ajax({
          url: constant.APIURL + 'comunidad/listarMisInscripcionesRa',
          type: 'POST',
          data: 'params={"agrupar":"agrupar_por_cursos"}',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          success: function success(response) {
            var r = JSON.parse(response);
            var data = r.data;
            var html = '';
            var contador = 0;
            r.data = r.data.sort(function (b, a) {
              return a.porcentaje_avance > b.porcentaje_avance ? 1 : b.porcentaje_avance > a.porcentaje_avance ? -1 : 0;
            });
            var curso = [];
            Object.keys(data).forEach(function (idx) {
              if (data[idx].nombre.length > 50) {
                var nomb = data[idx].nombre.toString();
                data[idx].nombre = nomb.substr(0, 47) + "...";
              }

              curso.push(data[idx]);
            });
            curso.sort(function (a, b) {
              return b.porcentaje_avance - a.porcentaje_avance;
            });
            var cont = 0;
            html += '<div class="col-12"><center><h2>Resultados de Busqueda<h2></center></div><div class="row">';

            if (constant.FIREBASE_SCORE != '') {
              //Vamos por la calificación de los cursos
              Ember.$.ajax({
                url: constant.FIREBASE_SCORE,
                type: 'POST',
                data: {
                  "method": "score_courses_resume"
                },
                success: function success(score_courses_resume) {
                  //Se le agrega la votación desde Firebase
                  r.data.forEach(function (item, idx) {
                    try {
                      /*r.data[idx].imagen_raprendizaje = curso_imagen[r.data[idx].grupo_k];*/
                      if (score_courses_resume.data) {
                        if (typeof score_courses_resume.data[r.data[idx].ra_k] != 'undefined') {
                          r.data[idx].ranking_raprendizaje = score_courses_resume.data[r.data[idx].ra_k].rate;
                        } else {
                          r.data[idx].ranking_raprendizaje = 0;
                        }
                      } else {
                        r.data[idx].ranking_raprendizaje = 0;
                      }
                    } catch (e) {
                      console.log(e);
                    }
                  });
                  Object.keys(curso).forEach(function (idx) {
                    var next = curso[idx + 1];
                    var prev = curso[idx - 1];
                    cont++;
                    /*
                     *  Obtenemos la imagen de la ruta de aprendizaje, a patir de los siguienttes criterios:
                     *
                     *  Si existe una referencia a una imagen desde la relación local ( curso_imagen ), para
                     *  la ruta de aprendizaje, se le da prioidad, en caso contario, se toma la imagen poveniente
                     *  del servicio.
                     * /
                    if (curso_imagen) {
                    if (curso_imagen[curso[idx].ra_k]) {
                    curso[idx].imagen_raprendizaje = curso_imagen[curso[idx].ra_k];
                    }
                    }
                     /*
                     * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
                     */

                    curso[idx].imagen_raprendizaje = solveImageURL(curso[idx].imagen_raprendizaje);

                    if (prev !== undefined) {
                      if (curso[idx].porcentaje_avance !== curso[idx - 1].porcentaje_avance) {
                        html += '<div class="col-12 col-sm-12 col-xl-12"><h2> <i class="fas fa-angle-up arrow-up"></i> ' + parseInt(curso[idx].porcentaje_avance) + '</h2></div><div class="col-12 col-sm-12 col-xl-12"><hr class="hr_divider_line"></div>';
                      }
                    } else if (cont === 1) {
                      html += '<div class="col-12 col-sm-12 col-xl-12"><h2> <i class="fas fa-angle-up arrow-up"></i> ' + parseInt(curso[idx].porcentaje_avance) + '</h2></div><div class="col-12 col-sm-12 col-xl-12"><hr class="hr_divider_line"></div>';
                    }

                    html += '<div class="col-xl-3 col-sm-6 col-12 margin_top_10">';
                    html += '<div class="card text-white text-center">';
                    html += '<a class="white_link" href="cursos/' + curso[idx].ra_k + '">';
                    html += '<img class="card-img" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image"/>';
                    html += '<div class="icon_approved">';

                    if (curso[idx].estatus === "8") {
                      html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" width="50" height="50" />';
                    }

                    html += '</div>';
                    html += '<div class="card-img-overlay img_gradient" style="top: auto; padding: 0px;">';
                    html += '<div class="row text-center">';
                    html += '<div class="col-12">';
                    html += '<ul class="horizontal-list no_padding" id="' + contador++ + '">';
                    html += self.drawStars(curso[idx].ranking_raprendizaje);
                    html += '</ul>';
                    html += '</div>';
                    html += '</div>';
                    html += '<p class="card-text ">' + curso[idx].nombre + '</p>';
                    html += '<p class="card-text uppercase"><small>' + curso[idx].nombre_comunidad + '</small></p>';
                    html += '</div>';
                    html += '<div class="icon_approved">';
                    html += '<img class="image_remove ' + curso[idx].estatus + '" src="' + curso[idx].imagen + '" width="50" height="50" hidden/>';
                    html += '</div>';
                    html += '</a>';
                    html += '</div>';
                    html += '<div class="row">';
                    html += '<div class="col-10">';
                    html += '<div class="progress margin_top_5">';
                    html += '<div class="progress-bar progress-bar-theme" role="progressbar" style=width:' + curso[idx].porcentaje_avance + '% aria-valuenow=' + curso[idx].porcentaje_avance;
                    html += 'aria-valuemin=' + curso[idx].porcentaje_avance + ' aria-valuemax=' + curso[idx].porcentaje_avance + '></div>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div class="col-2">';
                    html += '<p class="number_bar text-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>';
                    html += '</div>';
                    html += '</div>';
                    html += '</div>';
                  });
                  html += '<div class="col-12"><center><hr/></center></div>';
                  Ember.$('#searchContainer').html(html);
                }
              });
            } else {
              Object.keys(curso).forEach(function (idx) {
                var next = curso[idx + 1];
                var prev = curso[idx - 1];
                cont++;
                /*
                 *  Obtenemos la imagen de la ruta de aprendizaje, a patir de los siguienttes criterios:
                 *
                 *  Si existe una referencia a una imagen desde la relación local ( curso_imagen ), para
                 *  la ruta de aprendizaje, se le da prioidad, en caso contario, se toma la imagen poveniente
                 *  del servicio.
                 * /
                if (curso_imagen) {
                if (curso_imagen[curso[idx].ra_k]) {
                curso[idx].imagen_raprendizaje = curso_imagen[curso[idx].ra_k];
                }
                }
                 /*
                 * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
                 */

                curso[idx].imagen_raprendizaje = solveImageURL(curso[idx].imagen_raprendizaje);

                if (prev !== undefined) {
                  if (curso[idx].porcentaje_avance !== curso[idx - 1].porcentaje_avance) {
                    html += '<div class="col-12 col-sm-12 col-xl-12"><h2> <i class="fas fa-angle-up arrow-up"></i> ' + parseInt(curso[idx].porcentaje_avance) + '</h2></div><div class="col-12 col-sm-12 col-xl-12"><hr class="hr_divider_line"></div>';
                  }
                } else if (cont === 1) {
                  html += '<div class="col-12 col-sm-12 col-xl-12"><h2> <i class="fas fa-angle-up arrow-up"></i> ' + parseInt(curso[idx].porcentaje_avance) + '</h2></div><div class="col-12 col-sm-12 col-xl-12"><hr class="hr_divider_line"></div>';
                }

                html += '<div class="col-xl-3 col-sm-6 col-12 margin_top_10">';
                html += '<div class="card text-white text-center">';
                html += '<a class="white_link" href="cursos/' + curso[idx].ra_k + '">';
                html += '<img class="card-img" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image"/>';
                html += '<div class="icon_approved">';

                if (curso[idx].estatus === "8") {
                  html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" width="50" height="50" />';
                }

                html += '</div>';
                html += '<div class="card-img-overlay img_gradient" style="top: auto; padding: 0px;">';
                html += '<div class="row text-center">';
                html += '<div class="col-12">';
                html += '<ul class="horizontal-list no_padding" id="' + contador++ + '">';
                html += self.drawStars(curso[idx].ranking_raprendizaje);
                html += '</ul>';
                html += '</div>';
                html += '</div>';
                html += '<p class="card-text ">' + curso[idx].nombre + '</p>';
                html += '<p class="card-text uppercase"><small>' + curso[idx].nombre_comunidad + '</small></p>';
                html += '</div>';
                html += '<div class="icon_approved">';
                html += '<img class="image_remove ' + curso[idx].estatus + '" src="' + curso[idx].imagen + '" width="50" height="50" hidden/>';
                html += '</div>';
                html += '</a>';
                html += '</div>';
                html += '<div class="row">';
                html += '<div class="col-10">';
                html += '<div class="progress margin_top_5">';
                html += '<div class="progress-bar progress-bar-theme" role="progressbar" style=width:' + curso[idx].porcentaje_avance + '% aria-valuenow=' + curso[idx].porcentaje_avance;
                html += 'aria-valuemin=' + curso[idx].porcentaje_avance + ' aria-valuemax=' + curso[idx].porcentaje_avance + '></div>';
                html += '</div>';
                html += '</div>';
                html += '<div class="col-2">';
                html += '<p class="number_bar text-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>';
                html += '</div>';
                html += '</div>';
                html += '</div>';
              });
              html += '<div class="col-12"><center><hr/></center></div>';
              Ember.$('#searchContainer').html(html);
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      sortNombre: function sortNombre() {
        var self = this;
        var controller = self.controller;
        controller.set('comunidad', false);
        controller.set('valorados', false);
        controller.set('recien', false);
        controller.set('progeso', false);
        controller.set('nombre', true);
        Ember.$('.dropdown > button').text('Nombre');
        Ember.$('.dropdown .dropdown-menu a').show();
        Ember.$.ajax({
          url: constant.APIURL + 'comunidad/listarMisInscripcionesRa',
          data: 'params={"agrupar":"agrupar_por_cursos"}',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          success: function success(response) {
            var r = JSON.parse(response);
            r.data = r.data.sort(function (a, b) {
              return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
            });
            var data = r.data;
            var html = '';
            var contador = 0;
            var curso = [];
            Object.keys(data).forEach(function (idx) {
              if (data[idx].nombre.length > 50) {
                var nomb = data[idx].nombre.toString();
                data[idx].nombre = nomb.substr(0, 47) + "...";
              }

              curso.push(data[idx]);
            });
            curso.sort(function (a, b) {
              return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
            });
            var contador = 0;
            html += '<div class="col-12"><center><h2>Resultados de Búsqueda<h2></center></div><div class="row">';

            if (constant.FIREBASE_SCORE != '') {
              //Vamos por la calificación de los cursos
              Ember.$.ajax({
                url: constant.FIREBASE_SCORE,
                type: 'POST',
                data: {
                  "method": "score_courses_resume"
                },
                success: function success(score_courses_resume) {
                  //Se le agrega la votación desde Firebase
                  r.data.forEach(function (item, idx) {
                    try {
                      /*r.data[idx].imagen_raprendizaje = curso_imagen[r.data[idx].grupo_k];*/
                      if (score_courses_resume.data) {
                        if (typeof score_courses_resume.data[r.data[idx].ra_k] != 'undefined') {
                          r.data[idx].ranking_raprendizaje = score_courses_resume.data[r.data[idx].ra_k].rate;
                        } else {
                          r.data[idx].ranking_raprendizaje = 0;
                        }
                      } else {
                        r.data[idx].ranking_raprendizaje = 0;
                      }
                    } catch (e) {
                      console.log(e);
                    }
                  });
                  Object.keys(curso).forEach(function (idx) {
                    /*
                     *  Obtenemos la imagen de la ruta de aprendizaje, a patir de los siguienttes criterios:
                     *
                     *  Si existe una referencia a una imagen desde la relación local ( curso_imagen ), para
                     *  la ruta de aprendizaje, se le da prioidad, en caso contario, se toma la imagen poveniente
                     *  del servicio.
                     * /
                    if (curso_imagen) {
                    if (curso_imagen[curso[idx].ra_k]) {
                    curso[idx].imagen_raprendizaje = curso_imagen[curso[idx].ra_k];
                    }
                    }
                     /*
                     * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
                     */
                    curso[idx].imagen_raprendizaje = solveImageURL(curso[idx].imagen_raprendizaje);
                    html += '<div class="col-xl-3 col-sm-6 col-12 margin_top_10">';
                    html += '<div class="card text-white text-center">';
                    html += '<a class="white_link" href="cursos/' + curso[idx].ra_k + '">';
                    html += '<img class="card-img" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image"/>';
                    html += '<div class="icon_approved">';

                    if (curso[idx].estatus == "8") {
                      html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" width="50" height="50" />';
                    }

                    html += '</div>';
                    html += '<div class="card-img-overlay img_gradient" style="top: auto; padding: 0px;">';
                    html += '<div class="row text-center">';
                    html += '<div class="col-12">';
                    html += '<ul class="horizontal-list no_padding" id="' + contador++ + '">';
                    html += self.drawStars(curso[idx].ranking_raprendizaje);
                    html += '</ul>';
                    html += '</div>';
                    html += '</div>';
                    html += '<p class="card-text ">' + curso[idx].nombre + '</p>';
                    html += '<p class="card-text uppercase"><small>' + curso[idx].nombre_comunidad + '</small></p>';
                    html += '</div>';
                    html += '<div class="icon_approved">';
                    html += '<img class="image_remove ' + curso[idx].estatus + '" src="' + curso[idx].imagen + '" width="50" height="50" hidden/>';
                    html += '</div>';
                    html += '</a>';
                    html += '</div>';
                    html += '<div class="row">';
                    html += '<div class="col-10">';
                    html += '<div class="progress margin_top_5">';
                    html += '<div class="progress-bar progress-bar-theme" role="progressbar" style=width:' + curso[idx].porcentaje_avance + '% aria-valuenow=' + curso[idx].porcentaje_avance;
                    html += 'aria-valuemin=' + curso[idx].porcentaje_avance + ' aria-valuemax=' + curso[idx].porcentaje_avance + '></div>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div class="col-2">';
                    html += '<p class="number_bar text-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>';
                    html += '</div>';
                    html += '</div>';
                    html += '</div>';
                  });
                  html += '<div class="col-12"><center><hr/></center></div>';
                  Ember.$('#searchContainer').html(html);
                }
              });
            } else {
              Object.keys(curso).forEach(function (idx) {
                /*
                 *  Obtenemos la imagen de la ruta de aprendizaje, a patir de los siguienttes criterios:
                 *
                 *  Si existe una referencia a una imagen desde la relación local ( curso_imagen ), para
                 *  la ruta de aprendizaje, se le da prioidad, en caso contario, se toma la imagen poveniente
                 *  del servicio.
                 * /
                if (curso_imagen) {
                if (curso_imagen[curso[idx].ra_k]) {
                curso[idx].imagen_raprendizaje = curso_imagen[curso[idx].ra_k];
                }
                }
                 /*
                 * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
                 */
                curso[idx].imagen_raprendizaje = solveImageURL(curso[idx].imagen_raprendizaje);
                html += '<div class="col-xl-3 col-sm-6 col-12 margin_top_10">';
                html += '<div class="card text-white text-center">';
                html += '<a class="white_link" href="cursos/' + curso[idx].ra_k + '">';
                html += '<img class="card-img" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image"/>';
                html += '<div class="icon_approved">';

                if (curso[idx].estatus == "8") {
                  html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" width="50" height="50" />';
                }

                html += '</div>';
                html += '<div class="card-img-overlay img_gradient" style="top: auto; padding: 0px;">';
                html += '<div class="row text-center">';
                html += '<div class="col-12">';
                html += '<ul class="horizontal-list no_padding" id="' + contador++ + '">';
                html += self.drawStars(curso[idx].ranking_raprendizaje);
                html += '</ul>';
                html += '</div>';
                html += '</div>';
                html += '<p class="card-text ">' + curso[idx].nombre + '</p>';
                html += '<p class="card-text uppercase"><small>' + curso[idx].nombre_comunidad + '</small></p>';
                html += '</div>';
                html += '<div class="icon_approved">';
                html += '<img class="image_remove ' + curso[idx].estatus + '" src="' + curso[idx].imagen + '" width="50" height="50" hidden/>';
                html += '</div>';
                html += '</a>';
                html += '</div>';
                html += '<div class="row">';
                html += '<div class="col-10">';
                html += '<div class="progress margin_top_5">';
                html += '<div class="progress-bar progress-bar-theme" role="progressbar" style=width:' + curso[idx].porcentaje_avance + '% aria-valuenow=' + curso[idx].porcentaje_avance;
                html += 'aria-valuemin=' + curso[idx].porcentaje_avance + ' aria-valuemax=' + curso[idx].porcentaje_avance + '></div>';
                html += '</div>';
                html += '</div>';
                html += '<div class="col-2">';
                html += '<p class="number_bar text-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>';
                html += '</div>';
                html += '</div>';
                html += '</div>';
              });
              html += '<div class="col-12"><center><hr/></center></div>';
              Ember.$('#searchContainer').html(html);
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      sortRecent: function sortRecent() {
        var self = this;
        var controller = self.controller;
        controller.set('comunidad', false);
        controller.set('valorados', false);
        controller.set('recien', true);
        controller.set('progeso', false);
        controller.set('nombre', false);
        Ember.$('.dropdown > button').text('Recién agregados');
        Ember.$('.dropdown .dropdown-menu a').show();
        Ember.$.ajax({
          url: constant.APIURL + 'comunidad/listarMisInscripcionesRa',
          type: 'POST',
          data: 'params={"agrupar":"agrupar_por_cursos"}',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          success: function success(response) {
            var r = JSON.parse(response); //r.data = r.data.sort((a,b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));

            var data = r.data;
            var html = '';
            var contador = 0;
            var curso = [];
            Object.keys(data).forEach(function (idx) {
              if (data[idx].nombre.length > 50) {
                var nomb = data[idx].nombre.toString();
                data[idx].nombre = nomb.substr(0, 47) + "...";
              }

              curso.push(data[idx]);
            });
            curso.sort(function (a, b) {
              return new Date(b.lms_ra_fecha_publicacion).getTime() - new Date(a.lms_ra_fecha_publicacion).getTime();
            });
            var cont = 0;
            html += '<div class="col-12"><center><h2>Resultados de Busqueda<h2></center></div><div class="row">';

            if (constant.FIREBASE_SCORE != '') {
              //Vamos por la calificación de los cursos
              Ember.$.ajax({
                url: constant.FIREBASE_SCORE,
                type: 'POST',
                data: {
                  "method": "score_courses_resume"
                },
                success: function success(score_courses_resume) {
                  //Se le agrega la votación desde Firebase
                  r.data.forEach(function (item, idx) {
                    try {
                      /*r.data[idx].imagen_raprendizaje = curso_imagen[r.data[idx].grupo_k];*/
                      if (score_courses_resume.data) {
                        if (typeof score_courses_resume.data[r.data[idx].ra_k] != 'undefined') {
                          r.data[idx].ranking_raprendizaje = score_courses_resume.data[r.data[idx].ra_k].rate;
                        } else {
                          r.data[idx].ranking_raprendizaje = 0;
                        }
                      } else {
                        r.data[idx].ranking_raprendizaje = 0;
                      }
                    } catch (e) {
                      console.log(e);
                    }
                  });
                  Object.keys(curso).forEach(function (idx) {
                    var next = curso[idx + 1];
                    var prev = curso[idx - 1];
                    cont++;
                    /*
                     *  Obtenemos la imagen de la ruta de aprendizaje, a patir de los siguienttes criterios:
                     *
                     *  Si existe una referencia a una imagen desde la relación local ( curso_imagen ), para
                     *  la ruta de aprendizaje, se le da prioidad, en caso contario, se toma la imagen poveniente
                     *  del servicio.
                     * /
                    if (curso_imagen) {
                    if (curso_imagen[curso[idx].ra_k]) {
                    curso[idx].imagen_raprendizaje = curso_imagen[curso[idx].ra_k];
                    }
                    }
                     /*
                     * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
                     */

                    curso[idx].imagen_raprendizaje = solveImageURL(curso[idx].imagen_raprendizaje);

                    if (prev !== undefined) {
                      var d1 = new Date(curso[idx].lms_ra_fecha_publicacion);
                      var d2 = new Date(curso[idx - 1].lms_ra_fecha_publicacion); //if (curso[idx].lms_ra_fecha_publicacion !== curso[idx - 1].lms_ra_fecha_publicacion) {

                      if (d1.getMonth() + 1 !== d2.getMonth() + 1) {
                        html += '<div class="col-12 col-sm-12 col-xl-12"><h2> <i class="fas fa-angle-up arrow-up"></i> ' + curso[idx].lms_ra_fecha_publicacion.substring(0, 7) + '</h2></div><div class="col-12 col-sm-12 col-xl-12"><hr class="hr_divider_line"></div>';
                      }
                    } else if (cont === 1) {
                      html += '<div class="col-12 col-sm-12 col-xl-12"><h2> <i class="fas fa-angle-up arrow-up"></i> ' + curso[idx].lms_ra_fecha_publicacion.substring(0, 7) + '</h2></div><div class="col-12 col-sm-12 col-xl-12"><hr class="hr_divider_line"></div>';
                    }

                    html += '<div class="col-xl-3 col-sm-6 col-12 margin_top_10">';
                    html += '<div class="card text-white text-center">';
                    html += '<a class="white_link" href="cursos/' + curso[idx].ra_k + '">';
                    html += '<img class="card-img" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image"/>';
                    html += '<div class="icon_approved">';

                    if (curso[idx].estatus === "8") {
                      html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" width="50" height="50" />';
                    }

                    html += '</div>';
                    html += '<div class="card-img-overlay img_gradient" style="top: auto; padding: 0px;">';
                    html += '<div class="row text-center">';
                    html += '<div class="col-12">';
                    html += '<ul class="horizontal-list no_padding" id="' + contador++ + '">';
                    html += self.drawStars(curso[idx].ranking_raprendizaje);
                    html += '</ul>';
                    html += '</div>';
                    html += '</div>';
                    html += '<p class="card-text ">' + curso[idx].nombre + '</p>';
                    html += '<p class="card-text uppercase"><small>' + curso[idx].nombre_comunidad + '</small></p>';
                    html += '</div>';
                    html += '<div class="icon_approved">';
                    html += '<img class="image_remove ' + curso[idx].estatus + '" src="' + curso[idx].imagen + '" width="50" height="50" hidden/>';
                    html += '</div>';
                    html += '</a>';
                    html += '</div>';
                    html += '<div class="row">';
                    html += '<div class="col-10">';
                    html += '<div class="progress margin_top_5">';
                    html += '<div class="progress-bar progress-bar-theme" role="progressbar" style=width:' + curso[idx].porcentaje_avance + '% aria-valuenow=' + curso[idx].porcentaje_avance;
                    html += 'aria-valuemin=' + curso[idx].porcentaje_avance + ' aria-valuemax=' + curso[idx].porcentaje_avance + '></div>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div class="col-2">';
                    html += '<p class="number_bar text-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>';
                    html += '</div>';
                    html += '</div>';
                    html += '</div>';
                  });
                  html += '<div class="col-12"><center><hr/></center></div>';
                  Ember.$('#searchContainer').html(html);
                }
              });
            } else {
              Object.keys(curso).forEach(function (idx) {
                var next = curso[idx + 1];
                var prev = curso[idx - 1];
                cont++;
                /*
                 *  Obtenemos la imagen de la ruta de aprendizaje, a patir de los siguienttes criterios:
                 *
                 *  Si existe una referencia a una imagen desde la relación local ( curso_imagen ), para
                 *  la ruta de aprendizaje, se le da prioidad, en caso contario, se toma la imagen poveniente
                 *  del servicio.
                 * /
                if (curso_imagen) {
                if (curso_imagen[curso[idx].ra_k]) {
                curso[idx].imagen_raprendizaje = curso_imagen[curso[idx].ra_k];
                }
                }
                 /*
                 * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
                 */

                curso[idx].imagen_raprendizaje = solveImageURL(curso[idx].imagen_raprendizaje);

                if (prev !== undefined) {
                  if (curso[idx].fecha_creacion !== curso[idx - 1].fecha_creacion) {
                    html += '<div class="col-12 col-sm-12 col-xl-12"><h2> <i class="fas fa-angle-up arrow-up"></i> ' + curso[idx].fecha_creacion + '</h2></div><div class="col-12 col-sm-12 col-xl-12"><hr class="hr_divider_line"></div>';
                  }
                } else if (cont === 1) {
                  html += '<div class="col-12 col-sm-12 col-xl-12"><h2> <i class="fas fa-angle-up arrow-up"></i> ' + curso[idx].fecha_creacion + '</h2></div><div class="col-12 col-sm-12 col-xl-12"><hr class="hr_divider_line"></div>';
                }

                html += '<div class="col-xl-3 col-sm-6 col-12 margin_top_10">';
                html += '<div class="card text-white text-center">';
                html += '<a class="white_link" href="cursos/' + curso[idx].ra_k + '">';
                html += '<img class="card-img" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image"/>';
                html += '<div class="icon_approved">';

                if (curso[idx].estatus === "8") {
                  html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" width="50" height="50" />';
                }

                html += '</div>';
                html += '<div class="card-img-overlay img_gradient" style="top: auto; padding: 0px;">';
                html += '<div class="row text-center">';
                html += '<div class="col-12">';
                html += '<ul class="horizontal-list no_padding" id="' + contador++ + '">';
                html += self.drawStars(curso[idx].ranking_raprendizaje);
                html += '</ul>';
                html += '</div>';
                html += '</div>';
                html += '<p class="card-text ">' + curso[idx].nombre + '</p>';
                html += '<p class="card-text uppercase"><small>' + curso[idx].nombre_comunidad + '</small></p>';
                html += '</div>';
                html += '<div class="icon_approved">';
                html += '<img class="image_remove ' + curso[idx].estatus + '" src="' + curso[idx].imagen + '" width="50" height="50" hidden/>';
                html += '</div>';
                html += '</a>';
                html += '</div>';
                html += '<div class="row">';
                html += '<div class="col-10">';
                html += '<div class="progress margin_top_5">';
                html += '<div class="progress-bar progress-bar-theme" role="progressbar" style=width:' + curso[idx].porcentaje_avance + '% aria-valuenow=' + curso[idx].porcentaje_avance;
                html += 'aria-valuemin=' + curso[idx].porcentaje_avance + ' aria-valuemax=' + curso[idx].porcentaje_avance + '></div>';
                html += '</div>';
                html += '</div>';
                html += '<div class="col-2">';
                html += '<p class="number_bar text-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>';
                html += '</div>';
                html += '</div>';
                html += '</div>';
              });
              html += '<div class="col-12"><center><hr/></center></div>';
              Ember.$('#searchContainer').html(html);
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      sortRanking: function sortRanking() {
        var self = this;
        var controller = self.controller;
        controller.set('comunidad', false);
        controller.set('valorados', true);
        controller.set('recien', false);
        controller.set('progeso', false);
        controller.set('nombre', false);
        Ember.$('.dropdown > button').text('Mejor valorados');
        Ember.$('.dropdown .dropdown-menu a').show();
        Ember.$.ajax({
          url: constant.APIURL + 'comunidad/listarMisInscripcionesRa',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: 'params={"agrupar":"agrupar_por_cursos"}',
          success: function success(response) {
            var r = JSON.parse(response);
            var data = r.data;
            var html = '';
            var contador = 0;
            var curso = [];
            Object.keys(data).forEach(function (idx) {
              if (data[idx].nombre.length > 50) {
                var nomb = data[idx].nombre.toString();
                data[idx].nombre = nomb.substr(0, 47) + "...";
              }

              curso.push(data[idx]);
            });
            var cont = 0;
            html += '<div class="col-12"><center><h2>Resultados de Busqueda<h2></center></div><div class="row">';

            if (constant.FIREBASE_SCORE != '') {
              //Vamos por la calificación de los cursos
              Ember.$.ajax({
                url: constant.FIREBASE_SCORE,
                type: 'POST',
                data: {
                  "method": "score_courses_resume"
                },
                success: function success(score_courses_resume) {
                  //Se le agrega la votación desde Firebase
                  r.data.forEach(function (item, idx) {
                    try {
                      /*r.data[idx].imagen_raprendizaje = curso_imagen[r.data[idx].grupo_k];*/
                      if (score_courses_resume.data) {
                        if (typeof score_courses_resume.data[r.data[idx].ra_k] != 'undefined') {
                          r.data[idx].ranking_raprendizaje = score_courses_resume.data[r.data[idx].ra_k].rate;
                        } else {
                          r.data[idx].ranking_raprendizaje = 0;
                        }
                      } else {
                        r.data[idx].ranking_raprendizaje = 0;
                      }
                    } catch (e) {
                      console.log(e);
                    }
                  });
                  curso.sort(function (a, b) {
                    return a.ranking_raprendizaje < b.ranking_raprendizaje ? 1 : b.ranking_raprendizaje < a.ranking_raprendizaje ? -1 : 0;
                  });
                  Object.keys(curso).forEach(function (idx) {
                    var next = curso[idx + 1];
                    var prev = curso[idx - 1];
                    cont++;
                    /*
                     *  Obtenemos la imagen de la ruta de aprendizaje, a patir de los siguienttes criterios:
                     *
                     *  Si existe una referencia a una imagen desde la relación local ( curso_imagen ), para
                     *  la ruta de aprendizaje, se le da prioidad, en caso contario, se toma la imagen poveniente
                     *  del servicio.
                     * /
                    if (curso_imagen) {
                    if (curso_imagen[curso[idx].ra_k]) {
                    curso[idx].imagen_raprendizaje = curso_imagen[curso[idx].ra_k];
                    }
                    }
                     /*
                     * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
                     */

                    curso[idx].imagen_raprendizaje = solveImageURL(curso[idx].imagen_raprendizaje);

                    if (prev !== undefined) {
                      if (self.round(curso[idx].ranking_raprendizaje) !== self.round(curso[idx - 1].ranking_raprendizaje)) {
                        html += '<div class="col-12 col-sm-12 col-xl-12"><h2> <i class="fas fa-angle-up arrow-up"></i> ' + self.drawStars(curso[idx].ranking_raprendizaje) + '</h2></div><div class="col-12 col-sm-12 col-xl-12"><hr class="hr_divider_line"></div>';
                      }
                    } else if (cont === 1) {
                      html += '<div class="col-12 col-sm-12 col-xl-12"><h2> <i class="fas fa-angle-up arrow-up"></i> ' + self.drawStars(curso[idx].ranking_raprendizaje) + '</h2></div><div class="col-12 col-sm-12 col-xl-12"><hr class="hr_divider_line"></div>';
                    }

                    html += '<div class="col-xl-3 col-sm-6 col-12 margin_top_10">';
                    html += '<div class="card text-white text-center">';
                    html += '<a class="white_link" href="cursos/' + curso[idx].ra_k + '">';
                    html += '<img class="card-img" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image"/>';
                    html += '<div class="icon_approved">';

                    if (curso[idx].estatus === "8") {
                      html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" width="50" height="50" />';
                    }

                    html += '</div>';
                    html += '<div class="card-img-overlay img_gradient" style="top: auto; padding: 0px;">';
                    html += '<div class="row text-center">';
                    html += '<div class="col-10">';
                    html += '<ul class="horizontal-list no_padding" id="' + contador++ + '">';
                    html += self.drawStars(curso[idx].ranking_raprendizaje);
                    html += '</ul>';
                    html += '</div>';
                    html += '</div>';
                    html += '<p class="card-text ">' + curso[idx].nombre + '</p>';
                    html += '<p class="card-text uppercase"><small>' + curso[idx].nombre_comunidad + '</small></p>';
                    html += '</div>';
                    html += '<div class="icon_approved">';
                    html += '<img class="image_remove ' + curso[idx].estatus + '" src="' + curso[idx].imagen + '" width="50" height="50" hidden/>';
                    html += '</div>';
                    html += '</a>';
                    html += '</div>';
                    html += '<div class="row">';
                    html += '<div class="col-10">';
                    html += '<div class="progress margin_top_5">';
                    html += '<div class="progress-bar progress-bar-theme" role="progressbar" style=width:' + curso[idx].porcentaje_avance + '% aria-valuenow=' + curso[idx].porcentaje_avance;
                    html += 'aria-valuemin=' + curso[idx].porcentaje_avance + ' aria-valuemax=' + curso[idx].porcentaje_avance + '></div>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div class="col-2">';
                    html += '<p class="number_bar text-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>';
                    html += '</div>';
                    html += '</div>';
                    html += '</div>';
                  });
                  html += '<div class="col-12"><center><hr/></center></div>';
                  Ember.$('#searchContainer').html(html);
                }
              });
            } else {
              curso.sort(function (a, b) {
                return a.ranking_raprendizaje < b.ranking_raprendizaje ? 1 : b.ranking_raprendizaje < a.ranking_raprendizaje ? -1 : 0;
              });
              Object.keys(curso).forEach(function (idx) {
                var next = curso[idx + 1];
                var prev = curso[idx - 1];
                cont++;
                /*
                 *  Obtenemos la imagen de la ruta de aprendizaje, a patir de los siguienttes criterios:
                 *
                 *  Si existe una referencia a una imagen desde la relación local ( curso_imagen ), para
                 *  la ruta de aprendizaje, se le da prioidad, en caso contario, se toma la imagen poveniente
                 *  del servicio.
                 * /
                if (curso_imagen) {
                if (curso_imagen[curso[idx].ra_k]) {
                curso[idx].imagen_raprendizaje = curso_imagen[curso[idx].ra_k];
                }
                }
                 /*
                 * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
                 */

                curso[idx].imagen_raprendizaje = solveImageURL(curso[idx].imagen_raprendizaje);

                if (prev !== undefined) {
                  if (self.round(curso[idx].ranking_raprendizaje) !== self.round(curso[idx - 1].ranking_raprendizaje)) {
                    html += '<div class="col-12 col-sm-12 col-xl-12"><h2> <i class="fas fa-angle-up arrow-up"></i> ' + Math.round(curso[idx].ranking_raprendizaje > 4.9) + '</h2></div><div class="col-12 col-sm-12 col-xl-12"><hr class="hr_divider_line"></div>';
                  }
                } else if (cont === 1) {
                  html += '<div class="col-12 col-sm-12 col-xl-12"><h2> <i class="fas fa-angle-up arrow-up"></i> ' + curso[idx].ranking_raprendizaje + '</h2></div><div class="col-12 col-sm-12 col-xl-12"><hr class="hr_divider_line"></div>';
                }

                html += '<div class="col-xl-3 col-sm-6 col-12 margin_top_10">';
                html += '<div class="card text-white text-center">';
                html += '<a class="white_link" href="cursos/' + curso[idx].ra_k + '">';
                html += '<img class="card-img" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image"/>';
                html += '<div class="icon_approved">';

                if (curso[idx].estatus === "8") {
                  html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" width="50" height="50" />';
                }

                html += '</div>';
                html += '<div class="card-img-overlay img_gradient" style="top: auto; padding: 0px;">';
                html += '<div class="row text-center">';
                html += '<div class="col-10">';
                html += '<ul class="horizontal-list no_padding" id="' + contador++ + '">';
                html += self.drawStars(curso[idx].ranking_raprendizaje);
                html += '</ul>';
                html += '</div>';
                html += '</div>';
                html += '<p class="card-text ">' + curso[idx].nombre + '</p>';
                html += '<p class="card-text uppercase"><small>' + curso[idx].nombre_comunidad + '</small></p>';
                html += '</div>';
                html += '<div class="icon_approved">';
                html += '<img class="image_remove ' + curso[idx].estatus + '" src="' + curso[idx].imagen + '" width="50" height="50" hidden/>';
                html += '</div>';
                html += '</a>';
                html += '</div>';
                html += '<div class="row">';
                html += '<div class="col-10">';
                html += '<div class="progress margin_top_5">';
                html += '<div class="progress-bar progress-bar-theme" role="progressbar" style=width:' + curso[idx].porcentaje_avance + '% aria-valuenow=' + curso[idx].porcentaje_avance;
                html += 'aria-valuemin=' + curso[idx].porcentaje_avance + ' aria-valuemax=' + curso[idx].porcentaje_avance + '></div>';
                html += '</div>';
                html += '</div>';
                html += '<div class="col-2">';
                html += '<p class="number_bar text-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>';
                html += '</div>';
                html += '</div>';
                html += '</div>';
              }); //r.data = r.data.sort((a,b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));

              html += '<div class="col-12"><center><hr/></center></div>';
              Ember.$('#searchContainer').html(html);
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      }
    }
  });

  _exports.default = _default;
});