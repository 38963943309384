define("comunidades-nafin/routes/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var promise = new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(this, function () {
          resolve("ok");
        }, 1000);
      });
      return promise;
    },
    setupController: function setupController(controller) {
      this.controller = controller;
      $.ajax({
        url: constant.APIURL + 'reportes/dashboard/getDashboardByRol',
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        data: 'groupBy=categoria&groupDir=ASC',
        contentType: 'application/x-www-form-urlencoded',
        success: function success(r) {
          var respons = JSON.parse(r),
              categos = [],
              actual,
              indice = -1; //armar el arreglo como lo requiere ember

          respons.data.forEach(function (element, index) {
            if (actual != element.categoria) {
              indice++;
              actual = element.categoria;
              categos.push({
                categoria_k: element.categoria_k,
                nombre: element.categoria,
                cuenta: 0,
                cuentaString: 'Registro',
                childrens: []
              });
            }

            categos[indice].childrens.push(element);
            categos[indice].cuenta++;
            categos[indice].cuentaString = categos[indice].cuenta == 1 ? 'Registro' : ' Registros';
          }); //console.log( categos );

          controller.set('categorias', categos);
        }
      });
    },
    cargarGraficas: function cargarGraficas(params) {
      params = params || 'cargar_dependencias=1';
      $.ajax({
        url: constant.APIURL + 'reportes/dashboard/loadnewdashboard/' + this.dash_k,
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        data: params,
        contentType: 'application/x-www-form-urlencoded',
        success: function success(html) {
          //ar html = res.responseText;
          $('#espacioIframe').html('<iframe id="diviFrame" style="overflow:auto;width:100%;height:100%; background: white;" frameborder="0" ></iframe>');
          var iframe = document.getElementById('diviFrame');
          iframe.contentWindow.document.open();
          iframe.contentWindow.document.write(html);
          iframe.contentWindow.document.close();
        }
      });
    },
    getFiltroObject: function getFiltroObject() {
      var unindexed_array = $('#filtrosdiv').serializeArray();
      var indexed_array = {};
      $.map(unindexed_array, function (n, i) {
        if (n['value'] != 'xxxx') indexed_array[n['name']] = n['value'];
      });
      return indexed_array;
    },
    actions: {
      buscarConFiltro: function buscarConFiltro() {
        $('#espacioIframe').html('<img src="/img/loading_spinner.gif">');
        var filtro = JSON.stringify(this.getFiltroObject());
        console.info(filtro); // $('#btnBuscar').addClass('hidden');

        this.cargarGraficas('filtros=' + filtro + '&cargar_dependencias=1');
      },
      mostrarReporte: function mostrarReporte(reporte_k, dash_k, categorias) {
        categorias.forEach(function (c) {
          c.childrens.forEach(function (r) {
            Ember.set(r, "selected", false);
            if (reporte_k == r.reporte_k) Ember.set(r, "selected", true);
          });
        });
        var iframe = '';
        var self = this;
        self.dash_k = dash_k;
        $('#espacioIframe').html('<img src="/img/loading_spinner.gif">');
        $.ajax({
          url: constant.APIURL + 'reportes/reporteador/getReporte',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: 'reporte_k=' + reporte_k,
          success: function success(r) {
            var respons = JSON.parse(r);
            console.log(respons);
            var num = 1;
            var html = '';
            var columnas = respons.columnas;
            var filtros = respons.filtros;
            var filtrosHtml = '';
            filtros.forEach(function (rec) {
              //para cada uno de los filtros
              filtrosHtml += '<div class="input-group col-12 col-lg-6 margin_ud_10px">';
              if (rec.xtype == 'textfield') filtrosHtml += '<input type="text" class="form-control" name="' + rec.nombre_variable + '" placeholder="' + rec.nombre_etiqueta + '" />';

              if (rec.xtype == 'combo') {
                filtrosHtml += '<select id="filtro' + rec.filtro_k + '" class="form-control" name="' + rec.nombre_variable + '" placeholder="' + rec.nombre_etiqueta + '" /></select>';
                $.ajax({
                  url: constant.APIURL + 'reportes/reporteador/getComboData',
                  type: 'POST',
                  xhrFields: {
                    withCredentials: true
                  },
                  contentType: 'application/x-www-form-urlencoded',
                  data: 'reporte_k=' + rec.reporte_k + '&filtro_k=' + rec.filtro_k,
                  success: function success(r) {
                    var respons = JSON.parse(r);
                    var select = '<option value="xxxx">Seleccionar...</option>';
                    respons.data.forEach(function (val) {
                      select += '<option value="' + val.value + '">' + val.label + '</option>';
                    });
                    $('#filtro' + rec.filtro_k).append(select);
                  }
                });
              }

              filtrosHtml += '</div>';
            });

            if (filtrosHtml != '') {
              $('#filtrosdiv').html(filtrosHtml);
              $('#btnBuscar').removeClass('hidden');
            } else {
              $('#filtrosdiv').html('');
              $('#btnBuscar').addClass('hidden');
            } //-- cargar datos


            self.cargarGraficas();
          }
        });
      }
      /*
                  sht.post('reportes/dashboard','loadnewdashboard/'+ this.record.data.dashboard_k,{
                      scope: this,
                      params: {
                          cargar_dependencias: 1
                      },
                      success: function (res){
                          var iframe = document.getElementById('iFrame'+this.id),
                              iframe_element = Ext.get('iFrame'+this.id);
      
                          iframe_element.on({
                              scope:this,
                              load: function(){
                                  cmp.el.unmask();
                              }
                          });
      
                          var html = res.responseText;
                          iframe.contentWindow.document.open();
                          iframe.contentWindow.document.write(html);
                          iframe.contentWindow.document.close();
                      }
                  });
              }*/

    }
  });

  _exports.default = _default;
});