define("comunidades-nafin/components/event-card-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.bindScopeToParent(this);
    },
    bindScopeToParent: function bindScopeToParent(_scope) {
      this.onBindScopeToParent(_scope);
    },
    propagationAfterCreateEvent: function propagationAfterCreateEvent(data_object) {
      this.createEvent(data_object.storeEvent);
    },
    propagationAfterUpdateEvent: function propagationAfterUpdateEvent(data_object) {
      this.updateEvent(data_object.storeEvent);
    },
    propagationAfterDeleteEvent: function propagationAfterDeleteEvent(data_object) {
      this.deleteEvent(data_object.storeEvent);
    },
    createEvent: function createEvent(data_object) {
      var store_eventos = this.get('eventos');
      var date = data_object.start.split('-');
      var dateEnd = data_object.end.split('-');
      var month = date[1];
      var monthEnd = dateEnd[1];
      var _data_object = {
        activo: data_object.activo ? data_object.activo : "1",
        ad: data_object.ad ? data_object.ad : "0",
        anfitrion: data_object.anfitrion,
        anfitrion_k: data_object.anfitrion_k,
        calendario_k: data_object.calendario_k,
        cid: data_object.cid,
        dayFormath: date[2],
        descripcion: data_object.descripcion,
        duracion: data_object.duracion,
        end: data_object.end,
        estatus: data_object.estatus,
        fecha: data_object.fecha,
        fecha_fin: data_object.fecha_fin,
        formatt_hora: data_object.hora.substr(0, 5),
        formatt_hora_fin: data_object.hora_fin.substr(0, 5),
        hora: data_object.hora,
        hora_fin: data_object.hora_fin,
        id: data_object.id,
        isVisible: true,
        lugar: data_object.lugar,
        monthFormath: self.monthFormatter(month),
        monthFormathBody: date[2] + "/" + self.monthFormatter(month) + "/" + date[0],
        monthFormathBodyEnd: dateEnd[2] + "/" + self.monthFormatter(monthEnd) + "/" + dateEnd[0],
        nombre: data_object.nombre,
        notes: data_object.notes,
        observaciones: data_object.observaciones,
        propietario_k: data_object.propietario_k,
        start: data_object.start,
        tabla_propietario: data_object.tabla_propietario,
        tipo: data_object.tipo,
        title: data_object.title
      };
      store_eventos.pushObject(_data_object);
      self.set('eventos', store_eventos);
    },
    updateEvent: function updateEvent(data_object) {
      var _data_object = data_object;
      var store_eventos = this.get('eventos');
      store_eventos.forEach(function (_evento_record) {
        if (_data_object.calendario_k == Ember.get(_evento_record, 'calendario_k')) {
          var date = _data_object.start.split('-');

          var dateEnd = _data_object.end.split('-');

          var month = date[1];
          var monthEnd = dateEnd[1];
          Ember.set(_evento_record, 'monthFormath', self.monthFormatter(month));
          Ember.set(_evento_record, 'monthFormathBody', date[2] + "/" + self.monthFormatter(month) + "/" + date[0]);
          Ember.set(_evento_record, 'monthFormathBodyEnd', dateEnd[2] + "/" + self.monthFormatter(monthEnd) + "/" + dateEnd[0]);
          Ember.set(_evento_record, 'dayFormath', date[2]);
          Ember.set(_evento_record, 'formatt_hora', _data_object.hora.substr(0, 5));
          Ember.set(_evento_record, 'formatt_hora_fin', _data_object.hora_fin.substr(0, 5));
          Ember.set(_evento_record, 'isVisible', true);
          Ember.set(_evento_record, 'title', _data_object.title);
          Ember.set(_evento_record, 'nombre', _data_object.nombre);
          Ember.set(_evento_record, 'lugar', _data_object.lugar);
          Ember.set(_evento_record, 'descripcion', _data_object.descripcion);
        }
      });
      self.set('eventos', store_eventos);
    },
    deleteEvent: function deleteEvent(data_object) {
      var store_eventos = this.get('eventos');
      store_eventos.forEach(function (_evento_record) {
        if (data_object.calendario_k == Ember.get(_evento_record, 'calendario_k')) {
          Ember.set(_evento_record, 'isVisible', false);
        }
      });
      self.set('eventos', store_eventos);
    },
    didInsertElement: function didInsertElement() {
      var self = this._super.apply(this, arguments);

      this.set('isAdmin', this.session.get('isAdmin'));
      var d = new Date();
      var day = d.getDate();
      var month = d.getMonth() + 1;
      month = month < 10 ? '0' + month : month;
      var year = d.getFullYear();
      var date = new Date();
      var newDate = new Date(date.setDate(date.getDate() + 30));
      var newDay = newDate.getDate();
      var newYear = newDate.getFullYear();
      var newMonth = newDate.getMonth();
      newMonth = newMonth < 10 ? '0' + newMonth : newMonth;
      Ember.$.ajax({
        url: constant.APIURL + 'calendario/listarEventosPorUsuario',
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        contentType: 'application/x-www-form-urlencoded',
        data: 'params={"fecha":"' + year + '-' + month + '-' + day + '","start":"' + year + '-' + month + '-' + day + '","end":"' + newYear + '-' + newMonth + '-' + newDay + '"}',
        success: function success(response) {
          var data = JSON.parse(response);

          var _data = self.formatter(data.data);

          self.set('eventos', _data);
          Ember.$.ajax({
            url: constant.APIURL + 'usuario/datospersonales/',
            type: 'POST',
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            xhrFields: {
              withCredentials: true
            },
            success: function success(r) {
              var data = JSON.parse(r);
              console.log(data.configuracion.general_compatir_noticias_en_linkedin_habilitar); //Redes sociales

              if (data.configuracion.general_compatir_eventos_en_facebook_habilitar == false) {
                self.set("mostrar_facebook_compartir", false);
              } else {
                self.set("mostrar_facebook_compartir", true);
              }

              if (data.configuracion.general_compatir_eventos_en_twitter_habilitar == false) {
                self.set("mostrar_twitter_compartir", false);
              } else {
                self.set("mostrar_twitter_compartir", true);
              }

              if (data.configuracion.general_compatir_eventos_en_linkedin_habilitar == false) {
                self.set("mostrar_linkedin_compartir", false);
              } else {
                self.set("mostrar_linkedin_compartir", true);
              }
            },
            error: function error(e) {
              console.log(e);
            }
          });
        },
        error: function error(e) {
          console.log(e);
        }
      });

      window.fbAsyncInit = function () {
        FB.init({
          appId: '256998251751902',
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v3.1'
        });
      };

      (function (d, s, id) {
        var js,
            fjs = d.getElementsByTagName(s)[0];

        if (d.getElementById(id)) {
          return;
        }

        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    },
    setEvent: function setEvent(r) {
      this.set('eventos', r.data);
    },
    //Dar formato deseado a mes y fechas
    formatter: function formatter(data) {
      self = this;
      Object.keys(data).forEach(function (idx) {
        var date = data[idx].start.split('-');
        var dateEnd = data[idx].end.split('-');
        var month = date[1];
        var monthEnd = dateEnd[1];
        data[idx].monthFormath = self.monthFormatter(month);
        data[idx].monthFormathBody = date[2] + "/" + self.monthFormatter(month) + "/" + date[0];
        data[idx].monthFormathBodyEnd = dateEnd[2] + "/" + self.monthFormatter(monthEnd) + "/" + dateEnd[0];
        data[idx].dayFormath = date[2];
        data[idx].formatt_hora = data[idx].hora.substr(0, 5);
        data[idx].formatt_hora_fin = data[idx].hora_fin.substr(0, 5);
        data[idx].isVisible = true;
      });
      return data;
    },
    // Asignar el més en el que nos encontramos
    monthFormatter: function monthFormatter(month) {
      if (month === "01") {
        return "Enero";
      } else if (month === "02") {
        return "Febrero";
      } else if (month === "03") {
        return "Marzo";
      } else if (month === "04") {
        return "Abril";
      } else if (month === "05") {
        return "Mayo";
      } else if (month === "06") {
        return "Junio";
      } else if (month === "07") {
        return "Julio";
      } else if (month === "08") {
        return "Agosto";
      } else if (month === "09") {
        return "Septiembre";
      } else if (month === "10") {
        return "Octubre";
      } else if (month === "11") {
        return "Noviembre";
      } else {
        return "Diciembre";
      }
    },
    actions: {
      facebook: function facebook(e) {
        this._super();

        var self = this;
        var eventoIdetificador = Ember.$(e.target).parent().parent().parent().data('cursoidetificador');
        shareFB(constant.SHAREURL + 'evento/' + eventoIdetificador); //Agrega elemento en LOGROS

        window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre);
      },
      twitter: function twitter(e) {
        this._super();

        var self = this; // Selector al card del evento

        var elementoPadreDom = Ember.$(e.target).parent().parent().parent().parent().parent().parent().children(); // Arreglo con los elementos titulo, fecha, hora, lugar del card

        var detalleEventoDom = elementoPadreDom[1]; // Selector a tag ul de los elementos en detalle evento

        var dataEventoDom = Ember.$(detalleEventoDom).children()[0]; // Arreglo con elementos li con datos de titulo, fecha, hora, lugar del card

        var arregloDatosEventoDetalle = Ember.$(dataEventoDom).children();
        var titulo = arregloDatosEventoDetalle[0];
        var fecha = arregloDatosEventoDetalle[1];
        var hora = arregloDatosEventoDetalle[2];
        var lugar = arregloDatosEventoDetalle[3]; // obtenemos los strings generados en cada elemento

        var strTitulo = Ember.$(titulo).text();
        var strFecha = Ember.$(fecha).text();
        var strHora = Ember.$(hora).text();
        var strLugar = Ember.$(lugar).text();
        var eventoIdetificador = Ember.$(e.target).parent().parent().parent().data('cursoidetificador');
        var twitterMsm = strTitulo + ' del ' + strFecha + ' de ' + strHora + ' en ' + strLugar;
        var url = constant.SHAREURL + 'evento/' + eventoIdetificador;
        var width = 575,
            height = 400,
            left = (Ember.$(window).width() - width) / 2,
            top = (Ember.$(window).height() - height) / 2,
            url = 'https://twitter.com/share?url=' + url + '&text=' + twitterMsm,
            opts = 'status=1' + ',width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;
        window.open(url, 'twitter', opts); //Agrega elemento en LOGROS

        window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre);
      },
      linkedIn: function linkedIn(e) {
        this._super();

        var self = this;
        var eventoIdetificador = Ember.$(e.target).parent().parent().parent().data('cursoidetificador');
        shareLI(constant.SHAREURL + 'evento/' + eventoIdetificador); //Agrega elemento en LOGROS

        window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre);
      },

      /*
       *
       */
      showModalEditEvent: function showModalEditEvent(evento) {
        /*
         * Invocamos al método(acción) del compomente padre, que es el encargado de mostra el formulario de creación/edición del evento.
         */
        this.onShowModalEditEvent(evento);
      },

      /*
       *
       */
      showModalDeleteEvent: function showModalDeleteEvent(evento) {
        /*
             	 * Invocamos al método(acción) del compomente padre, que es el encargado de mostra el formulario de creación/edición del evento.
             	 */
        this.onshowModalDeleteEvent(evento);
      }
    }
  });

  _exports.default = _default;
});