define("comunidades-nafin/components/login-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    didInsertElement: function didInsertElement() {},
    actions: {
      captureKey: function captureKey(e) {
        if (e.keyCode === 13) this.send("loginRequest");
      },
      goCourses: function goCourses() {
        this._super.apply(this, arguments);

        var currentRoute = this.get('router').get('currentRouteName');

        if (currentRoute != 'cursos') {
          this.get('router').transitionTo('cursos');
        } else {
          document.location.href = '/cursos';
        }
      },
      loginRequest: function loginRequest() {
        var user = Ember.$('#user').val();
        var pass = Ember.$('#pass').val();
        var self = this;
        Ember.$("#loginRequest").hide();
        Ember.$.ajax({
          url: window.constant.APIURL + 'autentication/au',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: 'wpw=' + user + md5(pass) + '&recaptcha_challenge_field=&recaptcha_response_field=',
          success: function success(params) {
            var response = JSON.parse(params);
            Ember.$("#loginRequest").show();

            if (response.success) {
              Ember.$("body > *").text("");
              window.location.href = document.location.href.split("#")[0] + window.constant.APPURL;
              window.location.reload();
              /*if( response.redirect == false ){
                window.location.reload();
              }else{
                document.location = response.redirect;
              }*/
            } else {
              Ember.$("#errorLogin").removeClass("d-none").fadeIn(1500).fadeOut(6000);
            }
          },
          error: function error(e) {
            console.error(e);
          }
        });
      },
      registro: function registro() {
        document.location = document.location.href.split("#")[0] + "#/registro";
        setTimeout(function () {
          window.location.reload();
        }, 200);
      },
      recuperarContrasena: function recuperarContrasena() {
        Ember.$(".modal").css("background", "var(--main-color)");
        Ember.$("#modal_reset_password").modal("show");
      },
      enviarCorreoResetPassword: function enviarCorreoResetPassword() {
        Ember.$("#modal_reset_password").find(".modal-footer").hide();
        Ember.$.ajax({
          url: window.constant.APIURL + "tarea/requestresetpasswordapp",
          type: "post",
          data: {
            "username": Ember.$("#username").val()
          },
          dataType: "json",
          success: function success(response) {
            if (response.success) {
              Ember.$("#modal_reset_password").modal("hide");
              Ember.$("#modal_reset_password").find(".modal-footer").show();
              Ember.$('#modal-avisos .modal-body').html(response.msg);
              Ember.$('#modal-avisos').modal('show');
            } else {
              Ember.$("#modal_reset_password").find(".modal-footer").show();
              Ember.$('#modal-avisos .modal-body').html(response.msg);
              Ember.$('#modal-avisos').modal('show');
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      }
    }
  });

  _exports.default = _default;
});