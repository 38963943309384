define("comunidades-nafin/helpers/array-length", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayLength = arrayLength;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * arrayLength - Helper utilitario. Retorna el numero de elementos que contiene un array. Simil a Array.length
   *
   *
   * Helper utilitario. Retorna el numero de elementos que contiene un array. Simil a Array.length
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2018-10-04
   * @param   array param arreglo de valores a contar
   * @return  int numero de elementos en el arreglo
   */
  function arrayLength(params) {
    var _params = _slicedToArray(params, 1),
        arg1 = _params[0];

    if (!arg1) {
      return 0;
    }

    return arg1.length;
  }

  var _default = Ember.Helper.helper(arrayLength);

  _exports.default = _default;
});